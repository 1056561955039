import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	states,
	seriesTrusts,
	yup,
} from '_helpers';


// services
import {
	strategyService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// StrategyForm
function StrategyForm({
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast] = useState('');
	const [showToast, setShowToast] = useState(false);

	const [companies] = useState( JSON.parse( localStorage.getItem( `companies` ) || JSON.stringify( [] ) ) );

	// form schema
	let fields = {
		seriesName: yup.string().required(),
		strategyName: yup.string().required(),
		companyId: yup.string().required(),
		seriesFId: yup.number(),
		seriesStatusId: yup.number().required(),
		trusts: yup.array().of(yup.string()),
	};

	const schema = yup.object().shape(fields);

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors & toasts
		setError(false);

		// use object destructuring and the spread operator to omit trusts from values
		let {trusts,...Strategy} = values;

		// update seriesTrusts property
		Strategy = {
			...Strategy,
			seriesTrusts: seriesTrusts.filter((t) => {return trusts.indexOf(t.seriesTrustId) > -1})
		};

		// update Strategy
		if( Strategy.seriesId ) {

			strategyService.update(Strategy).then((response) => {
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
			});

		}
		// add Strategy
		else {

			strategyService.post(Strategy).then((response) => {
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
			});

		}

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={{
				...initialValues,
				trusts: initialValues.seriesTrusts.map(t => t.seriesTrustId),
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='seriesName'>{text.seriesName}*</Form.Label>
							<Form.Control
								type='text'
								name='seriesName'
								id='seriesName'
								value={values.seriesName}
								onChange={handleChange}
								isValid={touched.seriesName && !errors.seriesName}
								isInvalid={touched.seriesName && errors.seriesName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='strategyName'>{text.strategyName}*</Form.Label>
							<Form.Control
								type='text'
								name='strategyName'
								id='strategyName'
								value={values.strategyName}
								onChange={handleChange}
								isValid={touched.strategyName && !errors.strategyName}
								isInvalid={touched.strategyName && errors.strategyName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='companyId'>{text.company}*</Form.Label>
							<Form.Control
								as='select'
								className='form-select'
								id='companyId'
								value={values.companyId}
								onChange={handleChange}
								isValid={touched.companyId && !errors.companyId}
								isInvalid={touched.companyId && errors.companyId}
							>
								<option value={null}>—</option>
							{companies.map((company) =>
								<option key={company.companyId} value={company.companyId}>{company.companyName}</option>
							)}
							</Form.Control>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='seriesFId'>{text.seriesFId}</Form.Label>
							<Form.Control
								type='text'
								name='seriesFId'
								id='seriesFId'
								value={values.seriesFId}
								onChange={handleChange}
								isValid={touched.seriesFId && !errors.seriesFId}
								isInvalid={touched.seriesFId && errors.seriesFId}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='seriesStatusId'>{text.status}*</Form.Label>
							<Form.Control
								as='select'
								className='form-select'
								id='seriesStatusId'
								value={values.seriesStatusId}
								onChange={handleChange}
								isValid={touched.seriesStatusId && !errors.seriesStatusId}
								isInvalid={touched.seriesStatusId && errors.seriesStatusId}
							>
							{states.map((s) =>
								<option key={s.statusId} value={s.statusId}>{s.statusDescription}</option>
							)}
							</Form.Control>
						</Form.Group>

					</div>

				</div>

				<Form.Group className='mb-4'>
					<Form.Label>{text.trusts}*</Form.Label>
				{seriesTrusts.map(t =>
					<Form.Check
						key={t.seriesTrustId}
						type='checkbox'
						name='trusts'
						id={`seriesTrusts-${t.seriesTrustId}`}
						value={t.seriesTrustId}
						checked={values.trusts.indexOf(t.seriesTrustId)>-1}
						onChange={(e) => {
							const isChecked = values.trusts.indexOf(t.seriesTrustId) > -1;
							setFieldValue( 'trusts', isChecked ? values.trusts.filter((e) => {return e !== t.seriesTrustId;}) : [ ...values.trusts,t.seriesTrustId ] );
						}}
						label={<>
							{t.seriesTrustName}
						</>}
					/>
				)}
				</Form.Group>

				<Form.Group>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='form-text'>{text.requiredFields}</p>

			</Form>
		</>)}
		</Formik>
	)

}

export {
	StrategyForm,
};
