import React, {
	useContext,
	useEffect,
} from 'react';


// components
import {
	LoginForm,
	ForgotPasswordForm,
	ResetPasswordForm,
} from '_components';


// helpers
import {
	useQueryParams,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';
import { labels as labelsLogin } from '_i18n/Login';


// Login
function Login() {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsLogin[lang]};

	// queryParams
	const [queryParams] = useQueryParams();

	// Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		action = '',
		redirect = '',
		email = '',
		ResetToken = '',
	} = queryParams;

	// access + redirects
	useEffect(() => {
		if( User.isLoggedIn ) {
			window.location = redirect ? redirect : `/`;
		}
	}, [
		User,
		redirect,
	]);

	switch(action) {

		case 'forgot-password':

			return <>

				<div className='container-xs'>

					<h1 className='m-0 py-5'>{text.forgotPwTitle}</h1>

					<div className='alert alert-secondary mb-0'>{text.forgotPw}</div>

					<ForgotPasswordForm
						/>

				</div>

			</>;

		case 'reset-password':

			return <>

				<div className='container-xs'>

					<h1 className='m-0 py-5'>{text.resetPwTitle}</h1>

					<ResetPasswordForm
						initialValues={{
							ResetToken: ResetToken,
							Email: email,
							NewPassword: '',
							PasswordConfirmation: '',
						}}
						/>

				</div>
			</>;

		default:

			return User.isLoggedIn ? ( <></> ) : <>

				<div className='container-xs'>

					<h1 className='m-0 py-5'>{text.pageTitle}</h1>

				{'suspended' === action &&

					<div className='alert alert-warning'>
						{text.suspended||`We’ve temporarily suspended your account`}
					</div>

				}

					<LoginForm
						/>

				</div>

			</>;

	}

}

export { Login };
