/*
 * purchaseInfoFull service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/purchaseInfoFull
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/purchaseInfoFull`;


/**
 * GET list of purchaseInfoFull
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * POST
 *
 * @param  object params
 * @return object response
 */
function post(params={}) {
	return fetchWrapper.post(baseUrl, params);
}


// at your service :)
export const purchaseInfoFull = {
	baseUrl,
	get,
	post,
};
