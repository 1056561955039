import React, {
	useContext,
	useEffect,
} from 'react';


// components
import {
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
} from '_helpers';


// services
import {
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// Dashboard
function Dashboard() {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	// access + redirects
	useEffect(() => {
		if( !User.isLoggedIn ) {
			window.location = `/login?redirect=/dashboard`;
		}
	}, [
		User,
	]);

	// set page title
	useEffect(() => {
		document.title = [`Dashboard`, text.siteTitle].join(text.titleSeparator);
	});

	return User.isLoggedIn && (<>

		<div className='row py-5'>

			<div className='col-12 col-lg-8 mx-auto'>

			{!!( User.Client || User.Admin ) && <>
				<div className="list-group mx-auto py-5">
					<a href="/profile" className="list-group-item list-group-item-action">{text.profile}</a>
					<a href="/purchases" className="list-group-item list-group-item-action">{text.purchases}</a>
					<a href="/purchase" className="list-group-item list-group-item-action">{text.purchaseFunds}</a>
				{!!( User.Admin ) && <>
					<a href="/admin" className="list-group-item list-group-item-action">{text.admin}</a>
				</>}
					<button className="list-group-item list-group-item-action" onClick={User.logout}>{text.logOut}</button>
				</div>
			</>}

			{!User.Client && !User.Admin && <>
				<div className='alert alert-warning'>
				{'Pending' === User.Profile?.status &&
					<span>{`Your application is currently being reviewed. Please don't hesitate to`}</span>
				}
				{'Pending' !== User.Profile?.status &&
					<span>{`We noticed that you started an application for a brokerage account. We aim to assist you in completing the process so that you can begin investing. Please don't hesitate to`}</span>
				}
					<a className='alert-link px-1' href='/contact'>{`contact us`}</a>
					<span>{`if you have any inquiries or require assistance throughout the process.`}</span>
				</div>
			{'Pending' !== User.Profile?.status &&
				<a className='btn btn-primary d-block' href='/profile'>{`Finish Your Application`}</a>
			}
			</>}

			</div>

		</div>

	</>);
}

export { Dashboard };
