import React, {
	useContext,
	useEffect,
	useState,
} from 'react';


// components
import {
	UserForm,
	EditPasswordForm,
	Tabs,
	Tab,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	useQueries,
} from '_helpers';


// services
import {
	userService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


function Profile({
	tab='profile',	
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// initialValues
	const [initialValues, setInitialValues] = useState({});

	// access + redirects
	useEffect(() => {
		if( !User.isLoggedIn ) {
			window.location = `/login?redirect=/profile`;
		}
	}, [
		User,
	]);

	// run our queries
	const [
		{
			isLoading,
		}
	] = useQueries([
		{
			queryKey: ['profile'],
			queryFn: () => userService.getByEmail( User.email ),
			onSuccess: (response) => {

				setInitialValues( userService.parse(response) );

			},
			enabled: !!User.isLoggedIn && !!User.email
		},
	]);

	// set page title
	useEffect(() => {
		document.title = [`Profile`, text.siteTitle].join(text.titleSeparator);
	});

	return User.isLoggedIn && !isLoading && (<>

		<h1 className='m-0 py-5'>{text.profile}</h1>

		<Tabs
			className={'galaxy-tabs justify-content-center show-' + tab}
			defaultActiveKey={tab ? tab : 'profile'}>

			<Tab
				className='form-narrow'
				eventKey='profile'
				title={text.profile}>

				<UserForm
					initialValues={initialValues}
					/>

			</Tab>

			<Tab
				className='form-narrow'
				eventKey='password'
				title={text.Password}>

				<EditPasswordForm
					initialValues={{
						Email: User.email,
						NewPassword: '',
						PasswordConfirmation: '',
					}}
					/>

			</Tab>

		</Tabs>

	</>);
}

export { Profile };
