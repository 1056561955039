/*
 * purchaseRequest service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/purchaseRequest
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/purchaseRequest`;


/**
 * GET
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * POST
 *
 * @param  object params
 * @return object response
 */
function post(params={}) {
	return fetchWrapper.post(baseUrl, params);
}


/**
 * PATCH
 *
 * @param  string purchaseRequestId
 * @param  object params
 * @return object response
 */
function patch(purchaseRequestId,params={}) {
	return fetchWrapper.patch(`${baseUrl}/${purchaseRequestId}`, params);
}


/**
 * create purchaseRequest
 *
 * @param  object queryParams
 * @return object response
 */
function make() {
	return fetchWrapper.get(`${baseUrl}/new`);
}


/**
 * DELETE purchaseRequest - accepts string or object with purchaseRequestId prop
 *
 * @param  object|string props
 * @return object response
 */
function _delete(props) {
	const purchaseRequestId = 'object' === typeof props ? props.purchaseRequestId : props;
	return fetchWrapper.delete(`${baseUrl}/${purchaseRequestId}?purchaseRequestId=${purchaseRequestId}`);
}


// at your service :)
export const purchaseRequest = {
	baseUrl,
	get,
	post,
	patch,
	make,
	delete: _delete,
};
