export const labels = {
	'en': {
		firstName: 'First Name',
		lastName: 'Last Name',
		fullName: 'Name',
		Email: 'Email',
		country: 'Country',
		phonePrefix: 'Prefix',
		phonePrefixTooltip: 'An international call prefix or dial out code is a trunk prefix used to select an international telephone circuit for placing an international call.',
		phone: 'Phone',
		address1: 'Address',
		address2: 'Suite/Apt/Unit',
		cityTown: 'City',
		stateProvince: 'State/Province',
		postalCode: 'ZIP/Postal Code',
		website: 'Website',

		status: 'Status',
		in_progress: 'In Progress',
		pending: 'Pending',
		declined: 'Rejected/Declined',
		changes_needed: 'Rejected w/ Changes',
		approved: 'Approved',

		company: 'Company',
		companySaved: 'Company Saved',

		seriesTrustName: 'Series Trust Name',
		seriesTrustSaved: 'Trust Saved',

		profile: 'Profile',
		profileSaved: 'Profile Saved',
		passwordSaved: 'Password Updated',
		passwordReset: <>{`You can now`} <a className='alert-link' href='/login'>{`log in`}</a> {`with your new password (redirecting…)`}</>,
		username: 'Username',
		Password: 'Password',
		ConfirmPassword: 'Retype Password',
		NewPassword: 'New Password',
		PasswordConfirmation: 'Confirm New Password',
		createAccount: 'Create Account',
		saveChanges: 'Save Changes',
		register: 'Register',
		rememberMe: 'Keep me logged in',
		forgotPassword: 'Forgot password?',

		Roles: 'Roles',
		rolesUpdated: 'Roles updated',

		Documents: 'Documents',
		Files: 'Files',

		amount: 'Amount',
		amountToInvest: 'US Dollar Amount to Invest',
		fundingFactor: 'Funding Factor',
		fundingFactorEffective: 'Effective Funding Factor',
		fundingFactorMax: 'Max Funding Factor',
		investmentClass: 'Class',
		selectFund: 'Select Fund',
		tradingLevel: 'Trading Level',

		invalidEmail: 'Please enter a valid email address',
		invalidPhone: 'Please enter a valid phone number',
		invalidURL: 'Please enter a valid URL',
		PasswordMismatch: 'Passwords do not match',
		PasswordRequired: 'Please enter a password',
		PasswordMinLength: 'Password must be at least {min} characters',
		PasswordStrength: 'Must include a mixture of uppercase and lowercase letters',

		addAnother: 'add another',
		modify: 'Modify',
		remove: 'Remove',
		dateSubscription: 'Subscription Date',
		firstBusinessDay: 'First Business Day',
		mondayDealingDay: 'Monday',

		memorandumDocuments: 'I have read the memorandum documents, execution pages, and trading advisor supplement',
		mustAcknowledge: 'You must acknowledge before submitting your request',

		required: 'Required',
		requiredFields: '* Required fields',

		order: 'Order',
		yourOrder: 'Your Order',

		login: 'Log In',
		emailMe: 'Email me',
		submit: 'Submit',
		submitRequest: 'Submit Request',
		cancelRequest: 'Cancel Request',

		thankYou: 'Thank you!',
	},
};
