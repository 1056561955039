import React, {
	useContext,
} from 'react';


// components
import {
	Screener,
} from '_components';


// helpers
import {
	ADMIN_PURCHASE_COLUMNS,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// services
import {
	purchaseRequest,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// List
function List({
	enableFilters=true,
	enableQueryParams=false,
	defaultParams={},
	staticParams={},
	columns=null,
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	// column preferences
	columns = columns || ADMIN_PURCHASE_COLUMNS;

	const columnPrefs = JSON.parse( localStorage.getItem( `columns-purchases` ) || JSON.stringify( [] ) );

	if( columnPrefs.length ) {
		columns = columns.map(column => {
			let pref = columnPrefs.find(f=>f.field===column.field);
			return pref ? { ...column, visible: pref.visible } : { ...column };
		});
	}

	// filter columns for non-admins
	if( ! User.Admin ) {
		columns = columns.filter(column=>{return['actions','activate'].indexOf(column.field)===-1;});
	}

	return (<>
		<Screener
			columns={columns}
			primary={'purchaseRequestId'}
			service={purchaseRequest}
			defaultParams={{
				sortBy: 'dateCreated',
				orderBy: 'DESC',
				...defaultParams,
			}}
			staticParams={{
				...staticParams,
			}}
			enableQueryParams={false}
			queryKey={'purchases'}
			queryEnabled={!!User.Admin||!!User.Client}
			searchEnabled={false}
			toolbar={<>
				<div className='w-100 mb-2 d-sm-none'></div>
				<div className='col-auto ps-sm-0'>
					<a className='btn btn-outline-primary btn-sm' href={`/purchase`}>
						{text.purchaseFunds}
					</a>
				</div>
			</>}
			/>
	</>);

}

export { List };
