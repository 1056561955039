import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';


/*
 * React Query
 *
 * Use the QueryClientProvider component to connect and provide a QueryClient to our application
 *
 * @link https://react-query.tanstack.com/reference/QueryClient
 * @link https://react-query.tanstack.com/reference/QueryClientProvider
 *
 */
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from 'react-query';


// routes
import {
	routes,
} from './routes';


// components
import {
	Header,
} from '_components';


// helpers
import {
	GALAXY_VERSION,
	useRedirect,
	useRoutes,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


import {
	wordpressService,
} from '_services';


let logoutTimer;


function App() {

	// i18n
	const lang = 'en';

	// session handling
	const [session, setSession] = useState({});

	const clearLocalStorage = () => {

		localStorage.setItem( 'session', JSON.stringify({}) );

		[
			'purchaseItems',
			'companies',
			'user-roles',
		].forEach((arr) => {
			localStorage.setItem( arr, JSON.stringify([]) );
		});

		[
			'company',
			'files',
			'purchases',
			'series',
			'users',
		].forEach((col) => {
			localStorage.setItem( `columns-${col}`, JSON.stringify([]) );
		});

	};

	const login = useCallback((userData) => {

		const appVersion = GALAXY_VERSION;

		if( parseFloat( ( localStorage.getItem( `appVersion` ) || 0 ) ) < appVersion ) {
			clearLocalStorage();
			localStorage.setItem( 'appVersion', appVersion );
		}

		setSession(userData);

		localStorage.setItem( 'session', JSON.stringify(userData) );

	}, []);

	const logout = useCallback(() => {

		clearLocalStorage();

		setSession({});

		document.cookie = `galaxy_access=true;path=/;max-age=0;`;

		window.location = `/login?redirect=${document.location.pathname}`;

	}, []);

	const refresh = useCallback((session) => {

		wordpressService.refreshToken(session.refresh_token)

			.then((response) => {

				if( response && response.success ) {

					const max_age = (response.expires_in * 1000);

					login({
						...session,
						expires_in: ( new Date() ).getTime() + max_age,
						access_token: response.access_token,
						refresh_token: response.refresh_token,
					});

					document.cookie = `galaxy_access=true;path=/;max-age=${max_age}`;

					window.location.reload();

				}

				else {
					logout();
				}

			}

		).catch((error) => {
			console.log( error.toString() );
		});

	}, [
		login,
		logout,
	]);


	// set up our QueryClient
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: false,
				refetchOnWindowFocus: false,
			},
		},
		queryCache: new QueryCache({
			onError: (error, query) => {
				console.log(error);
			},
		}),
	});


	// redirects
	useRedirect( '/', `/dashboard` );


	// useRoutes
	const routeResults = useRoutes(routes);


	// here we go…
	useEffect(() => {

		// always scroll to top…
		window.scrollTo(0, 0);

		// load session from localStorage if possible
		const session = JSON.parse( localStorage.getItem('session') || JSON.stringify({
			expires_in: ( new Date() ).getTime() + (3599 * 1000)
		}) );

		// remainingTime
		const remainingTime = session.expires_in - ( new Date() ).getTime();
		const refreshMinute = 600 * 1000; // 10 minutes

		if( session.access_token && session.refresh_token ) {

			if( session.expires && remainingTime < 0 ) {

				// out of time, log out
				logout();

			}

			else if( remainingTime < refreshMinute ) {

				// refresh
				refresh(session);

			}

			else if( 'localhost' !== window.location.hostname && session.Admin && ! document.body.classList.contains('logged-in') ) {

				// must be logged in to WordPress…
				logout();

			}

			else {

				// login with stored session
				login(session);

			}

		}

		else {

			login({anonymous:true});

		}

	}, [
		login,
		logout,
		refresh,
	]);


	// session handler
	useEffect(() => {

		if( session && session.expires ) {

			const remainingTime = session.expires_in - ( new Date() ).getTime();

			logoutTimer = setTimeout(logout, remainingTime);

		}
		else {

			clearTimeout(logoutTimer);

		}

	}, [
		session,
		logout,
	]);


	return (
		<UserModel.Provider value={{
			...session,
			lang: lang,
			isLoggedIn: !!session.access_token,
			login: login,
			logout: logout,
			refresh: refresh,
		}}>
		{(session.access_token || session.anonymous) &&
			<QueryClientProvider client={queryClient}>
			{'localhost' === window.location.hostname && <>
				<Header />
				<section>
					<div className='container'>
						{routeResults||<h1 className='m-0 py-5'>{`Page Not Found`}</h1>}
					</div>
				</section>
			</>}
			{'localhost' !== window.location.hostname &&
				<div className='container'>
					{routeResults||<h1 className='m-0 py-5'>{`Page Not Found`}</h1>}
				</div>
			}
			</QueryClientProvider>
		}
		</UserModel.Provider>
	);
}

export { App };
