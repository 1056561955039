import React, {
	useContext,
	useEffect,
	useRef,
} from 'react';


// components
import {
	Modal,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// ConfirmModal
function ConfirmModal({
	showConfirm=false,
	handleCloseConfirm=()=>{},
	confirmCallback=()=>{},
	message=null,
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	// confirmButton
	const confirmButton = useRef(null);

	useEffect(() => {
		// focus confirm button on render
		if( confirmButton.current )
			confirmButton.current.focus();
	});

	return (
		<Modal show={showConfirm} onHide={handleCloseConfirm} centered>

			<Modal.Header className='border-0' closeButton>
			</Modal.Header>

			<Modal.Body className='px-5 pb-5'>

				<h5 className='mb-4'>{message ? message : text.confirmDelete}</h5>

				<button
					ref={confirmButton}
					type='button'
					className='btn btn-primary me-2'
					onClick={confirmCallback}>
					{text.yes}
				</button>

				<button
					type='button'
					className='btn btn-outline-primary'
					onClick={handleCloseConfirm}>
					{text.no}
				</button>

			</Modal.Body>

		</Modal>
	)

}

export { ConfirmModal };
