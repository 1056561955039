// environment
export const environment = [
	'localhost',
	'galaxyplus.test',
	'dev.galaxyplus.io',
	'test.galaxyplus.io',
].indexOf(window.location.hostname) > -1 ? 'test' : 'live';

// platform
const platform = 'test' === environment ? 'galaxyapi' : 'galaxyplusapi';

// domain
const domain = 'test' === environment ? 'enki.us' : 'azurewebsites.net';

// API
export const BASE_URL = `https://${platform}.${domain}`;
export const API_URL = BASE_URL + '/api';

// React App
export const GALAXY_VERSION = 202403111620;

// Dropbox
export const DROPBOX_SIGN_CLIENT_ID = 'test' === environment ? 'be8dd2adb019ef2db4144313eabd4fda' : '3c952ff98601a5223bc3758172d3a06f';

// WordPress
export const WP_BASE_URL = ['localhost','galaxyplus.test','test.galaxyplus.io'].indexOf(window.location.hostname) > -1 ? 'http://test.galaxyplus.io' : ( 'https://' + window.location.hostname );
export const UPLOADS_URL = WP_BASE_URL + '/wp-content/uploads';
export const WP_AJAX_URL = WP_BASE_URL + '/wp-admin/admin-ajax.php';

// Dates
export const NOW = new Date().getUTCFullYear();

export const YEARS = Array(NOW - 1956).fill('').map((v, idx) => NOW - idx);

export const MONTHS = Array.from({length: 12}, (e, i) => {
	return {
		month: (i < 9 ? '0' : '') + (i + 1),
		label: new Date(null, i + 1, null).toLocaleDateString('en', {month: 'long'})
	};
});

// seriesTrusts
export const seriesTrusts = [
	{
		seriesTrustId: '2c7958b1-186a-4652-81e7-6d5c91374763',
		seriesTrustName: 'Galaxy Plus Hedge Fund LLC',
		seriesTrustUri: 'galaxy-plus-hedge-fund-llc',
	},
	{
		seriesTrustId: 'b1451548-c233-4e5c-980c-86a3d02729a5',
		seriesTrustName: 'Galaxy Plus Fund LLC',
		seriesTrustUri: 'galaxy-plus-fund-llc',
	},
	{
		seriesTrustId: '13f2d730-4a3d-4a1a-8915-faac779e3652',
		seriesTrustName: 'Galaxy Plus Fund SPC',
		seriesTrustUri: 'galaxy-plus-fund-spc',
	},
];

// states
export const states = [
	{
		statusId: 1,
		statusDescription: 'Active',
	},
	{
		statusId: 2,
		statusDescription: 'Pending',
	},
	{
		statusId: 3,
		statusDescription: 'Onboarding',
	},
	{
		statusId: 4,
		statusDescription: 'Closed',
	},
	{
		statusId: 5,
		statusDescription: 'Deleted',
	},
];
