/*
 * company service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/company
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/company`;


/**
 * GET list of companies
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * GET by companyId
 *
 * @param  string purchaseRequestId
 * @param  object params
 * @return object response
 */
function getById(companyId) {
	return fetchWrapper.get(`${baseUrl}/${companyId}`);
}


/**
 * POST
 *
 * @param  object params
 * @return object response
 */
function post(params={}) {
	return fetchWrapper.post(baseUrl, params);
}


/**
 * Update company record via PUT
 *
 * @param  object params
 * @return object response
 */
function update(params={}) {
	return fetchWrapper.put(baseUrl, params);
}


/**
 * DELETE company - accepts string or object with companyId prop
 *
 * @param  object|string props
 * @return object response
 */
function _delete(props) {
	const companyId = 'object' === typeof props ? props.companyId : props;
	return fetchWrapper.delete(`${baseUrl}/${companyId}`);
}


// at your service :)
export const companyService = {
	baseUrl,
	get,
	getById,
	post,
	update,
	delete: _delete,
};
