import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	DatePicker,
	Dropdown,
	Notifications,
	NumericFormat,
} from '_components';


// helpers
import {
	dateFormat,
	subMonths,

	purchaseItemInfo,

	states,

	getSymbolFromCurrency,

	BASE_URL,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// services
import {
	purchaseRequest,
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';
import { labels as labelsTables } from '_i18n/Tables';


// Table
function Table({
	cols=[],
	rows=[],
	small=false,
	sticky=false,
	nested=false,
	striped=true,
	primary=null,
	isLoading=false,
	toolbar=null,
	params={},
	setParams=()=>{},
	removeRow=null,
	selectRow=()=>{},
	selectAll=()=>{},
	fns={
		activate:()=>{},
		updateProperty:()=>{},
		updatePropertyCallback:()=>{},
	},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsTables[lang]};


	// error handling & toasts
	const [error] = useState(false);
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// params
	const [sortBy, setSortBy] = useState(params.sortBy);
	const [orderBy, setOrderBy] = useState(params.orderBy);

	const updateParams = (obj) => {
		setSortBy(obj.sortBy);
		setOrderBy(obj.orderBy);
		setParams(obj);
	};


	// only use visible columns…
	const columns = cols.filter((col) => {
		return col.visible !== false;
	});


	// allRowsSelected
	const [allRowsSelected, setAllRowsSelected] = useState(false);


	// tableClass
	const tableClass = () => {

		let classes = [
			'table',
		];

		if( small )

			classes.push('table-sm');

		if( striped )

			classes.push('table-striped');

		if( isLoading )

			classes.push('fade');

		return classes.join(' ');

	};


	// headerClass
	const headerClass = ({
		col={type:'',format:'',field:''},
		classes=[],
	}) => {

		// default classes based on type
		switch(col.type) {

			case 'action':
			case 'actions':
			case 'activate':
			case 'boolean':
			case 'status':

				classes.push('text-center');

			break;

			case 'currency':
			case 'float':
			case 'int':
			case 'percent':

				classes.push('text-end');

			break;

			case 'checkbox':

				classes.push('text-nowrap');

			break;

			default:

				classes.push('text-start');
		}

		// classes based on field
		switch(col.field) {

			case 'alert':

				classes.push('text-center');

			break;

			case 'color':
			case 'past12Months':

				classes.push('text-nowrap');

			break;

			case 'actions':

				classes.push('col-actions');

			break;

			default:
		}

		if( col.sortable )

			classes.push('col-sortable');

		return classes.join(' ');

	};


	// headerName
	const headerName = (col) => {

		switch(col.field) {

			case 'monthReturn':

				let headerName = dateFormat( subMonths( new Date(), 1 ), 'MMM' );

				if( params.filterByDatePerformanceMin && params.filterByDatePerformanceMax ) {
					headerName = <>{dateFormat( new Date(params.filterByDatePerformanceMin), 'M/yyyy' )} – <br/>{dateFormat( new Date(params.filterByDatePerformanceMax), 'M/yyyy' )}</>;
				}

				return headerName;

			default:

				return text[col.field] || col.headerName;

		}

	};



	// sortIcon
	const sortIcon = (col={field:''},field='') => {
		switch(col.field) {
			case 'name':
			case 'fullName':
				field = 'lastName';
			break;
			default:
				field = col.field;
		};
		return field === sortBy ? (
			<i className={ 'DESC' === orderBy ? 'fas fa-caret-down ps-1' : 'fas fa-caret-up ps-1' }></i>
		) : (<></>);
	};



	return (<>
		<Notifications
			error={error}
			toast={toast}
			showToast={showToast}
			setShowToast={setShowToast}
			/>
	{!!sticky &&
		<div className='sticky-top sticky-top-screener'>

			{toolbar}

			<Table
				cols={cols}
				rows={rows}
				small={small}
				sticky={false}
				nested={true}
				striped={striped}
				primary={primary}
				isLoading={isLoading}
				params={params}
				setParams={setParams}
				removeRow={removeRow}
				selectRow={selectRow}
				selectAll={selectAll}
				/>

		</div>
	}
	{!sticky && !!isLoading &&
		<>{text.loading}</>
	}
	{!sticky && !nested && toolbar}
		<div className={'table-responsive opacity-' + (!!isLoading ? 50 : 100) + (!rows.length ? ' d-none' : '') } onScroll={(e) => {
			const sib = e.target.previousSibling;
			if( sib.classList.contains('sticky-top') ) {
				sib.querySelectorAll('.table-responsive')[0].scrollLeft = e.target.scrollLeft;
			}
		}}>

			<table className={tableClass()}>
				<thead>
					<tr>
					{columns.map((col) =>
						<th key={col.field}
							scope='col'
							className={headerClass({col:col})}
							colSpan={col.colSpan}
							data-field={col.field}
							onClick={(e) => {
								if( !col.sortable ) return;
								let params = {};
								switch(col.field) {
									case 'name':
									case 'fullName':
										params = {...params, sortBy: 'lastName'};
									break;
									default:
										params = {...params, sortBy: col.field};
								}
								updateParams({
									...params,
									orderBy: 'DESC' === orderBy ? 'ASC' : 'DESC'
								});
							}}>
						{'checkbox' === col.type && <>
							<div className='custom-control custom-checkbox'>
								<input type='checkbox' className='custom-control-input' id='toggleAllRows'
									checked={allRowsSelected}
									onChange={(e) => {
										setAllRowsSelected(e.currentTarget.checked);
										selectAll(e.currentTarget.checked);
									}}/>
								<label className='custom-control-label' htmlFor='toggleAllRows'></label>
							</div>
						</>}
						{'checkbox' !== col.type && <>
							<span>{headerName(col)}</span>
							{sortIcon(col)}
						</>}
						</th>
					)}
					</tr>
				</thead>
				<tbody>
				{rows.map((row) =>
					<tr key={row[primary]}>
					{columns.map((col) =>
						<Cell
							key={`${col.field}-${row[primary]}`}
							row={row}
							col={col}
							primary={primary}
							nested={nested}
							removeRow={removeRow}
							selectRow={selectRow}
							updateParams={updateParams}
							setToast={setToast}
							setShowToast={setShowToast}
							fns={fns}
							/>
					)}
					</tr>
				)}
				</tbody>
			</table>

		</div>
	</>);

}

Table.Cell = Cell;
Table.Options = Options;

// Cell
function Cell({
	row={},
	col={colSpan:1,field:'',type:'',subField:'',subType:'',editable:false},
	primary=null,
	nested=false,
	alwaysDisplayReturns=false,
	headerOnly=false,
	readOnly=true,
	chartKey='',
	removeRow=()=>{},
	selectRow=()=>{},
	setToast=()=>{},
	setShowToast=()=>{},
	updateParams=()=>{},
	fns={
		activate:()=>{},
		updateProperty:()=>{},
		updatePropertyCallback:()=>{},
	}
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsTables[lang]};


	// isEditing
	const [isEditing, setIsEditing] = useState(false);


	// cellClass
	const cellClass = (classes=[]) => {

		const value = row[col.field];

		// default classes based on type
		switch(col.type) {

			case 'action':
			case 'actions':
			case 'activate':
			case 'boolean':
			case 'status':

				classes.push('text-center');

			break;

			case 'currency':
			case 'float':
			case 'int':
			case 'percent':

				classes.push('text-end');

			break;

			default:

				classes.push('text-start');
		}

		// custom formatting
		switch(col.format) {

			case 'plusMinus':

				classes.push('text-nowrap');
				classes.push( value === 0 ? '' : (value > 0 ? 'text-success' : 'text-danger') );

			break;

			default:

				classes.push('');

		}

		return classes.join(' ');

	};


	// cellValue
	const cellValue = () => {

		const value = row[col.field];

		const toCurrency = (number) => {

			const formatter = new Intl.NumericFormat('en-US', {
				style: 'decimal',
				currency: 'USD'
			});

			return formatter.format(number);

		};

		switch(col.field) {

			case 'actions':

				return (
					<div className='d-flex justify-content-center'>
						<button className='btn btn-link py-0 text-danger'
							onClick={(e) => {removeRow(row);}}>
							<i className='fas fa-times-circle'></i>
						</button>
					</div>
				);

			case 'activate':

				const btnClass = 'btn btn-outline-primary btn-sm py-0 px-2';
				const btnStyle = {width:'100px'};

				return 'Pending' === row?.status ? (
					<div className='d-flex justify-content-center'>
						<button
							className={btnClass}
							style={btnStyle}
							onClick={(e) => {
								e.target.hidden = true;
								e.target.disabled = true;
								e.target.nextSibling.hidden = false;
								const tr = e.target.closest('tr');
								fns.activate(row).then(() => {
									// show toast
									setToast(text.userActivated);
									setShowToast(true);
									// remove button
									e.target.nextSibling.remove();
									// update status
									tr.querySelector('[data-field="status"]').innerHTML = text.approved;
								});
							}}>
							{text.activate}
						</button>
						<span className={`${btnClass} disabled`} style={btnStyle} hidden>{text.working}</span>
					</div>
				) : '';

			case 'color':

				return (
					<div className='shadow shadow-sm' style={{backgroundColor:value,width:'2rem',height:'2rem'}}></div>
				);

			case 'companyName':

				return (
					<a className='d-block' href={`/admin/companies/${row.companyId}`}>
						<i className='fas fa-edit me-1'></i>{row.companyName}
					</a>
				);

			case 'purchaseRequestStatusId':

				const purchaseRequestStates = ['In Progress','Submitted','Completed','Rejected'];

				return User.Admin ? (
					<select className='form-select form-select-sm' defaultValue={value} onChange={(e) => {
						const SELECT = e.currentTarget;
						purchaseRequest.patch(row.purchaseRequestId, [
							{
								op: 'replace',
								path: '/purchaseRequestStatusId',
								value: SELECT.value
							}
						]).then((response) => {
							const notifyAdmin = wordpressService.sendEmail( {
								name: 'purchase-request-status-change',
								userId: row.userId,
								status: purchaseRequestStates[SELECT.value],
								purchaseItems: JSON.stringify( row.purchaseItems ),
							} );
							const notifyUser = wordpressService.sendEmail( {
								name: 'user-purchase-request-status-change',
								userId: row.userId,
								status: purchaseRequestStates[SELECT.value],
								purchaseItems: JSON.stringify( row.purchaseItems ),
							} );
							Promise.all([notifyAdmin, notifyUser]).then(() => {
								SELECT.blur();
							});
						});
					}}>
					{purchaseRequestStates.map((purchaseRequestStatusId,index) =>
						<option key={index} value={index}>{purchaseRequestStatusId}</option>
					)}
					</select>
				) : <>{purchaseRequestStates[value]}</>;

			case 'purchaseItems':

				return (
					<>
					{value.map((item) =>
						<div className='purchaseItem' key={item.purchaseInfoId}>
							{purchaseItemInfo(item)}
						</div>
					)}
					{!!row.pdf &&
						<a target='_blank' rel='noreferrer' className='d-block pt-2' href={`${BASE_URL}${row.pdf}`}>
							<i className='fas fa-file-pdf me-1'></i>{text.downloadPDF}
						</a>
					}
					</>
				);

			case 'fileName':

				return (
					<a target='_blank' rel='noreferrer' href={`${BASE_URL}${row.uri}`}>
						<i className='fa-solid fa-fw fa-file me-1'></i>
						<span>{row.title||row.fileNameFull}</span>
					</a>
				);

			case 'fullName':

				return (
					<>
						{row.firstName} {row.lastName}
					</>
				);

			case 'fundId':

				return (
					<a className='d-block' href={`/admin/strategies/${col.seriesId}/funds/${row.fundId}`}>
						<i className='fas fa-edit me-1'></i>{row.fundName}
					</a>
				);

			case 'fundStatusId':

				const status = states.find(s => s.statusId === value);

				return status ? status.statusDescription : '';

			case 'seriesName':

				return (
					<a className='d-block' href={`/admin/strategies/${row.seriesId}`}>
						<i className='fas fa-edit me-1'></i>{row.seriesName}
					</a>
				);

			case 'seriesTrustName':

				return (
					<a className='d-block' href={`/admin/trusts/${row.seriesTrustId}`}>
						<i className='fas fa-edit me-1'></i>{row.seriesTrustName}
					</a>
				);

			case 'seriesTrusts':

				return (
					<>
					{value.map((item) =>
						<div className='seriesTrust' key={item.seriesTrustId}>
							{item.seriesTrustName}
						</div>
					)}
					</>
				);

			case 'name':

				return (
					<a className='d-block' href={`/admin/users/${row.userId}`}>
						<i className='fas fa-edit me-1'></i>{row.firstName} {row.lastName}
					</a>
				);

			case 'status':

				return 'In Progress' === value ? (
					<a target='_blank' rel='noreferrer' className='d-block' href={`/profile/overview/?email=${row.email}`}>
						{value}<i className='fas fa-edit ms-1'></i>
					</a>
				) : value;

			default:

				switch(col.type) {

					case 'boolean':

						return (
							<i className='fa-solid fa-check'></i>
						);

					case 'currency':

						if( col.editable && !readOnly ) {

							switch(col.field) {

								case 'quantity':

									return isEditing ? (
										<div className='d-flex align-items-center justify-content-end'>
											<div className='input-group input-group-sm' style={{width:'130px'}}>
												<div className='input-group-prepend'>
													<span className='input-group-text'>$</span>
												</div>
												<input
													type='number'
													min={row.investmentMin}
													className='form-control text-end'
													value={value}
													onChange={(e) => {
														fns.updateProperty(row, 'quantity', e.currentTarget.value);
													}}
													onBlur={(e) => {
														setIsEditing(false);
														fns.updatePropertyCallback(row, 'quantity', e.currentTarget.value);
													}}
													aria-label={col.headerName}
													/>
											</div>
										</div>
									) : (
										<div className='d-flex align-items-center justify-content-end'>
											<div className='input-group input-group-sm' style={{width:'130px'}}>
												<div className='input-group-prepend'>
													<span className='input-group-text'>$</span>
												</div>
												<input
													readOnly
													type='text'
													className='form-control text-end bg-white'
													value={toCurrency(value)}
													onFocus={(e) => {setIsEditing(true);}}
													aria-label={col.headerName}
													/>
											</div>
										</div>
									);

								default:

									return value;

							}

						}
						else {

							let currencySymbol = getSymbolFromCurrency(row.currency);

							return (
								<NumericFormat value={value}
									displayType={'text'}
									thousandSeparator={true}
									decimalScale={0}
									renderText={(value, props) => <div>{currencySymbol}{value}</div>}/>
							);

						}

					case 'code':

						return (
							<code>{value}</code>
						);

					case 'date':

						if( col.editable ) {

							return (
								<div className='d-flex align-items-center justify-content-start' style={{width:'80px'}}>
									<DatePicker
										selected={new Date(value)}
										dateFormat='M/d/yyyy'
										onChange={(date) => {
											fns.updateProperty(row, 'dateAllocation', date);
											fns.updatePropertyCallback(row, 'dateAllocation', date);
										}}
										showMonthDropdown
										showYearDropdown
										minDate={new Date(row.dateInception)}
										maxDate={new Date()}
										dropdownMode='select'
										className='form-control form-control-sm'
									/>
								</div>
							);

						}
						else {

							let format = col.dateFormat ? col.dateFormat : 'M/d/yyyy';

							return dateFormat(new Date(value), format);

						}

					case 'percent':

						let precision = ( 'number' === typeof col.precision ) ? col.precision : 2;
						let show_sign = col.show_sign === true ? '%' : '';

						return value ? (value * 100).toFixed(precision) + show_sign : '—';

					case 'float':

						if( col.editable && !readOnly ) {

							switch(col.field) {

								default:

									return value;

							}

						}
						else {

							return (
								<NumericFormat value={value}
									displayType={'text'}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							);

						}

					case 'int':

						return (value ? value : '—');

					default:

						return value;

				}

		}

	};


	if( col.colSpan > 0 ) {
		return (<>
			{row[col.field].map((col,i) =>
				<Cell
					key={i}
					row={col}
					col={{
						field: col.subField,
						type: col.subType,
					}}
					nested={nested}
					removeRow={removeRow}
					selectRow={selectRow}
					/>
			)}
		</>)
	}
	else {
		return (
			<td data-field={col.field}
				className={cellClass()}>
			{'actions' === col.field &&
				<button className='btn btn-link py-0 text-danger'
					onClick={(e) => {removeRow(row);}}>
					<i className='fas fa-times-circle'></i>
				</button>
			}
			{'checkbox' === col.type && !nested &&
				<div className='custom-control custom-checkbox'>
					<input type='checkbox' className='custom-control-input' id={`${col.field}-${row[primary]}`}
						checked={row.selected}
						onChange={(e) => {selectRow(row);}}/>
					<label className='custom-control-label' htmlFor={`${col.field}-${row[primary]}`}></label>
				</div>
			}
			{'actions' !== col.field && ['checkbox'].indexOf(col.type) === -1 && cellValue()}
			</td>
		)
	}
}

// Options
function Options({
	size='normal',
	model='',
	columns=[],
	setColumns,
	children,
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsTables[lang]};

	return (
		<Dropdown className={`dropdown-table-options dropdown-table-${size}`}>

			<Dropdown.Toggle variant='btn-link btn-sm' id='table-options-toggle' bsPrefix={'galaxy-toggle'}>
				<i className='fas fa-sliders-h pe-2'></i>
				<span>{text.filters}</span>
			</Dropdown.Toggle>

			<Dropdown.Menu className='p-4 shadow' align='end'>

				<div className={children?'row':''}>

					{children}

					<div className={children?'col-6 col-md-4 ml-auto':''}>
						<div className='mb-0'>
							<h6 className='badge bg-dark'>{`Columns`}</h6>
						{columns.filter((column) => {return !column.alwaysVisible;}).map((column) =>
							<div key={column.field} className='form-check'>
								<input type='checkbox' className='form-check-input' id={`show-${column.field}`}
									checked={column.visible!==false}
									onChange={(e) => {
										let visibleColumns = columns.map(col => {
											return col.field === column.field ? { ...col, visible: !col.visible } : { ...col };
										});

										setColumns(visibleColumns);

										// save column preferences
										localStorage.setItem( `columns-${model}`, JSON.stringify( visibleColumns.map(col => {
											return {'field':col.field,'visible':col.alwaysVisible||col.visible};
										}) ) );
									}}/>
								<label className='form-check-label' htmlFor={`show-${column.field}`}>{text[column.field]||column.headerName}</label>
							</div>
						)}
						</div>

					</div>

				</div>

			</Dropdown.Menu>

		</Dropdown>
	);
}

export { Table };
