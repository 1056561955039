export const ADMIN_COMPANY_COLUMNS = [
	{
		field: 'companyName',
		headerName: 'Company Name',
		sortable: true,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'location',
		headerName: 'Location',
		sortable: false,
		visible: true,
	},
	{
		field: 'actions',
		headerName: '',
		sortable: false,
		visible: true,
		alwaysVisible: true,
	},
];

export const ADMIN_SERIES_TRUSTS_COLUMNS = [
	{
		field: 'seriesTrustName',
		headerName: 'Name',
		sortable: true,
		visible: true,
		alwaysVisible: true,
	},
];

export const ADMIN_FILE_COLUMNS = [
	{
		field: 'fileName',
		headerName: 'Title',
		sortable: true,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'fileNameFull',
		headerName: 'Filename',
		sortable: false,
		visible: false,
	},
	{
		field: 'filesTypeDescription',
		headerName: 'Description',
		sortable: false,
		visible: true,
	},
	{
		field: 'actions',
		headerName: '',
		sortable: false,
		visible: true,
		alwaysVisible: true,
	},
];

export const ADMIN_FUND_COLUMNS = [
	{
		field: 'fundId',
		headerName: 'Fund',
		sortable: true,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'fundingFactorMax',
		headerName: 'Max Funding Factor',
		sortable: false,
		visible: true,
	},
	{
		field: 'fundStatusId',
		headerName: 'Status',
		sortable: false,
		visible: true,
	},
	{
		field: 'seriesTrusts',
		headerName: 'Trusts',
		sortable: false,
		visible: true,
	},
	{
		field: 'actions',
		headerName: '',
		sortable: false,
		visible: true,
		alwaysVisible: true,
	},
];

export const ADMIN_STRATEGY_COLUMNS = [
	{
		field: 'seriesName',
		headerName: 'Series',
		sortable: true,
		visible: true,
	},
	{
		field: 'strategyName',
		headerName: 'Strategy',
		sortable: true,
		visible: true,
	},
	{
		field: 'dateCreated',
		headerName: 'Created',
		sortable: true,
		visible: true,
		type: 'date',
		dateFormat: "M/d/yyyy 'at' h:mmaaa",
	},
];

export const ADMIN_USER_COLUMNS = [
	{
		field: 'name',
		headerName: 'Name',
		sortable: true,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'email',
		headerName: 'Email',
		sortable: true,
		visible: true,
	},
	{
		field: 'phone',
		headerName: 'Phone',
		sortable: false,
		visible: true,
	},
	{
		field: 'createdUtc',
		headerName: 'Joined',
		sortable: true,
		visible: true,
		type: 'date',
		dateFormat: "M/d/yyyy 'at' h:mmaaa",
	},
	{
		field: 'status',
		headerName: 'Status',
		sortable: false,
		visible: true,
	},
	{
		field: 'activate',
		headerName: '',
		sortable: false,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'actions',
		headerName: '',
		sortable: false,
		visible: true,
		alwaysVisible: true,
	},
];

export const ADMIN_PURCHASE_COLUMNS = [
	{
		field: 'fullName',
		headerName: 'Name',
		sortable: true,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'purchaseItems',
		headerName: 'Purchased',
		sortable: false,
		visible: true,
	},
	{
		field: 'purchaseRequestStatusId',
		headerName: 'Status',
		sortable: false,
		visible: true,
	},
	{
		field: 'dateSubscription',
		headerName: 'Subscription Date',
		sortable: true,
		visible: true,
		type: 'date',
		dateFormat: "M/d/yyyy",
	},
	{
		field: 'firstBusinessDay',
		type: 'boolean',
		sortable: false,
		visible: false,
	},
	{
		field: 'mondayDealingDay',
		type: 'boolean',
		sortable: false,
		visible: false,
	},
	{
		field: 'dateCreated',
		headerName: 'Created',
		sortable: true,
		visible: true,
		type: 'date',
		dateFormat: "M/d/yyyy 'at' h:mmaaa",
	},
	{
		field: 'actions',
		headerName: '',
		sortable: false,
		visible: true,
		alwaysVisible: true,
	},
];
