// components
import { Admin } from 'admin';
import { Dashboard } from 'dashboard';
import { Login } from 'login';
import { Profile } from 'profile';
import { Purchases } from 'purchase';
import { Purchase } from 'purchase';

// establish routes
const routes = {};

[
	'en',
].forEach((lang) => {

	[
		'',
		'/',
	].forEach((s) => {

		routes[`/admin${s}`] = () =>
		<>
			<Admin />
		</>

		routes[`/admin/:tab${s}`] = ({tab}) =>
		<>
			<Admin
				tab={tab}
				/>
		</>

		routes[`/admin/:tab/:id${s}`] = ({tab,id}) =>
		<>
			<Admin
				tab={tab}
				id={id}
				/>
		</>

		routes[`/admin/:tab/:id/:sub${s}`] = ({tab,id,sub}) =>
		<>
			<Admin
				tab={tab}
				id={id}
				sub={sub}
				/>
		</>

		routes[`/admin/:tab/:id/:sub/:sid${s}`] = ({tab,id,sub,sid}) =>
		<>
			<Admin
				tab={tab}
				id={id}
				sub={sub}
				sid={sid}
				/>
		</>

		routes[`/admin/:tab/:id/:sub/:sid/:gub${s}`] = ({tab,id,sub,sid,gub}) =>
		<>
			<Admin
				tab={tab}
				id={id}
				sub={sub}
				sid={sid}
				gub={gub}
				/>
		</>

		routes[`/dashboard${s}`] = () =>
		<>
			<Dashboard />
		</>

		routes[`/login${s}`] = () =>
		<>
			<Login />
		</>

		routes[`/profile${s}`] = () =>
		<>
			<Profile />
		</>

		routes[`/purchase${s}`] = () =>
		<>
			<Purchase />
		</>

		routes[`/purchases${s}`] = () =>
		<>
			<Purchases />
		</>

	});

});

export { routes };
