/*
 * userAccounts service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/userAccounts
 *
 */


// helpers
import {
	API_URL,

	fetchWrapper,
	dateFormat,
	parseDate,

} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/userAccounts`;


/**
 * GET list of user accounts
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * GET user data from email
 *
 * @param  string email
 * @param  object headers - needed during login to submit auth token
 * @return object response
 */
function getByEmail(email='',headers={}) {
	return fetchWrapper.get( `${baseUrl}`, {
		email: encodeURIComponent( email ),
	}, headers );
}


/**
 * Update user record via PUT
 *
 * @param  string userId
 * @param  object params
 * @return object response
 */
function update(userId='',params={}) {
	return fetchWrapper.put(`${baseUrl}/${userId}`, params);
}


/**
 * Update user password via POST
 *
 * @param  object params
 * @return object response
 */
function editPassword(params={}) {
	return fetchWrapper.post(`${baseUrl}/editpassword`, params);
}


/**
 * Activate user via POST
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/userAccounts/userAccounts_activateuserById
 *
 * @param  string userId
 * @return object response
 */
function activate(userId,params={}) {
	return fetchWrapper.post(`${baseUrl}/activateuser/${userId}`, params);
}


/**
 * DELETE user - accepts string or object with userId prop
 *
 * @param  object|string props
 * @return object response
 */
function _delete(props) {
	const userId = 'object' === typeof props ? props.userId : props;
	return fetchWrapper.delete(`${baseUrl}/${userId}`);
}


/*
 * Retrieves a list of user roles
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/userAccounts/userAccounts_GetRoles
 *
 */
function getRoles() {
	return fetchWrapper.get(`${baseUrl}/roles`);
}

function addRoles(UserId,params) {
	return fetchWrapper.put(`${baseUrl}/${UserId}/addroles`, params);
}

function removeRoles(UserId,params) {
	return fetchWrapper.delete(`${baseUrl}/${UserId}/removeroles`, params);
}


/**
 * Parses + adds additional properties to a user response
 *
 * @param  object response - typically from a getByEmail response
 * @return object
 *
 */
function parse(response) {

	let UserProfile = response.UserProfile;

	for (var prop in UserProfile) {
		switch(prop) {
			case 'country':
				UserProfile.country = 'USA' === UserProfile.country ? 'US' : UserProfile.country;
			break;
			case 'website':
				UserProfile.website = UserProfile.website ? (UserProfile.website.indexOf('http') === 0 ? UserProfile.website : 'http://' + UserProfile.Websiwebsitete) : '';
			break;
			default:
				UserProfile[prop] = UserProfile[prop] ? UserProfile[prop] : '';
		}
	}

	return {
		...UserProfile,
		UserId: response.UserId,
		UserGuid: response.UserGuid,
		Email: response.Email,
		RoleNames: response.RoleNames,
		lockedOut: response.LockoutEndUtc && dateFormat( parseDate( response.LockoutEndUtc ), 'yyyyMMddHms' ) > dateFormat( new Date(), 'yyyyMMddHms' ),
	};

}


// at your service :)
export const userService = {
	baseUrl,
	get,
	getByEmail,
	update,
	editPassword,
	activate,
	parse,
	delete: _delete,
	getRoles,
	addRoles,
	removeRoles,
};
