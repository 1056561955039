export const labels = {
	'en': {
		pageTitle: 'Account Login',
		forgotPwTitle: 'Forgot Password',
		resetPwTitle: 'Reset Password',
		noAccount: 'No account found associated with this email.',
		invalidPw: 'The password you entered is invalid.',
		forgotPw: 'Enter your email address and you’ll receive an email with instructions to reset your password.',
		emailNotFound: 'Email not found',
		emailFound: 'Check your email for instructions to reset your password.',
	},
};
