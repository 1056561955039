import React, {
	useContext,
} from 'react';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// Pagination
function Pagination({
	params={},
	setParams=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	const resultsFrom = params.pageNumber <= 1 ? 1 : (params.pageNumber - 1) * params.pageSize + 1;
	const resultsTo = (params.pageSize * params.pageNumber) > params.totalCount ? params.totalCount : (params.pageSize * params.pageNumber);

	return (
		<div className='row gx-0 align-items-center'>

			<div className='col-auto d-none d-sm-block'>

				<span className='pe-2'>
					{resultsFrom} – {resultsTo} {text.of} {params.totalCount}
				</span>

				<span className='pe-2'>
					<b>{text.page}</b>
				</span>

				<span className='pe-2'>
					<input className='form-control form-control-sm form-control-pageNumber d-inline-block'
						type='number'
						step='1'
						min='1'
						max={params.totalPages||1}
						value={params.pageNumber||1}
						onChange={(e) => {setParams({pageNumber:e.currentTarget.value})}}/>
				</span>

				<span className='pe-2'>
					{text.of} {params.totalPages}
				</span>

			</div>

			<div className='col-auto'>

				<button className='btn btn-sm btn-primary border-primary me-1'
					disabled={parseInt(params.pageNumber) === 1}
					onClick={(e) => {e.preventDefault();setParams({pageNumber:(parseInt(params.pageNumber)-1)})}}>
					<i className='fas fa fa-chevron-left mx-1'></i>
				</button>

				<button className='btn btn-sm btn-primary border-primary'
					disabled={parseInt(params.pageNumber) === params.totalPages}
					onClick={(e) => {e.preventDefault();setParams({pageNumber:(parseInt(params.pageNumber)+1)})}}>
					<i className='fas fa fa-chevron-right mx-1'></i>
				</button>

			</div>

		</div>
	);
}

export { Pagination };
