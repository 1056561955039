import {
	NumericFormat,
} from '_components';


// helpers
import {
} from '_helpers';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// MoneyFormat
export const MoneyFormat = (value) => {

	// nine zeroes for billions
	return Math.abs(Number(value)) >= 1.0e+9

	? (Math.abs(Number(value)) / 1.0e+9).toFixed(1) + "B"

	// six zeroes for millions 
	: Math.abs(Number(value)) >= 1.0e+6

	? (Math.abs(Number(value)) / 1.0e+6).toFixed(1) + "M"

	// three zeroes for thousands
	: Math.abs(Number(value)) >= 1.0e+3

	? (Math.abs(Number(value)) / 1.0e+3).toFixed(0) + "K"

	: Math.abs(Number(value));

};


// purchaseItemInfo
export const purchaseItemInfo = (item,lang='en') => {

	// i18n
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	return (<>
		<h6 className={'m-0'}>{item.companyName} <span className={'fw-normal'}>— {item.strategyName}</span></h6>
		<p className='mb-1'>{item.seriesTrustName} — {item.seriesName} ({item.seriesFId})</p>
		<p className='d-flex mb-0'>
			<span className='pe-3'>
				<b className='pe-1'>{text.amount}:</b>
				<NumericFormat value={item.amount}
					displayType={'text'}
					thousandSeparator={true}
					decimalScale={0}
					renderText={(value, props) => <span>${value}</span>}
					/>
			</span>
			<span className='pe-3'>
				<b className='pe-1'>{text.investmentClass}:</b>
				<span>{item.investmentClass}</span>
			</span>
			<span className='pe-3'>
				<b className='pe-1'>{text.fundingFactorEffective}:</b>
				<span>{item.fundingFactorEffective}</span>
			</span>
		{parseFloat(item.fundingFactorEffective) !== 1 &&
			<span className='pe-3'>
				<b className='pe-1'>{text.tradingLevel}:</b>
				<NumericFormat value={item.amount*item.fundingFactorEffective}
					displayType={'text'}
					thousandSeparator={true}
					decimalScale={0}
					renderText={(value, props) => <span>${value}</span>}
					/>
			</span>
		}
		</p>
	</>);

};
