import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	states,
	yup,
} from '_helpers';


// services
import {
	fundService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// FundForm
function FundForm({
	initialValues={},
	seriesTrusts=[],
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// form schema
	let fields = {
		fundName: yup.string().required(),
		fundingFactorMax: yup.number(),
		fundStatusId: yup.number().required(),
		trusts: yup.array().of(yup.string()),
	};

	const schema = yup.object().shape(fields);

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors & toasts
		setError(false);

		// use object destructuring and the spread operator to omit trusts from values
		let {seriesId, trusts,...Fund} = values;

		// update seriesTrusts property
		Fund = {
			...Fund,
			seriesTrusts: seriesTrusts.filter((t) => {return trusts.indexOf(t.seriesTrustId) > -1})
		};

		// update Fund
		if( Fund.fundId ) {

			fundService.update(seriesId,Fund).then((response) => {
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
			});

		}
		// add Fund
		else {

			fundService.post(seriesId,Fund).then((response) => {
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
			});

		}

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={{
				...initialValues,
				trusts: initialValues.seriesTrusts.map(t => t.seriesTrustId),
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='fundName'>{text.fundName}*</Form.Label>
							<Form.Control
								type='text'
								name='fundName'
								id='fundName'
								value={values.fundName}
								onChange={handleChange}
								placeholder={!!values.fundId?'':`${text.addFund}…`}
								isValid={touched.fundName && !errors.fundName}
								isInvalid={touched.fundName && errors.fundName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='fundingFactorMax'>{text.fundingFactorMax}</Form.Label>
							<Form.Control
								type='number'
								step='.5'
								min='0'
								name='fundingFactorMax'
								id='fundingFactorMax'
								value={values.fundingFactorMax}
								onChange={handleChange}
								isValid={touched.fundingFactorMax && !errors.fundingFactorMax}
								isInvalid={touched.fundingFactorMax && errors.fundingFactorMax}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='fundStatusId'>{text.status}*</Form.Label>
							<Form.Control
								as='select'
								className='form-select'
								id='fundStatusId'
								value={values.fundStatusId}
								onChange={handleChange}
								isValid={touched.fundStatusId && !errors.fundStatusId}
								isInvalid={touched.fundStatusId && errors.fundStatusId}
							>
							{states.map((s) =>
								<option key={s.statusId} value={s.statusId}>{s.statusDescription}</option>
							)}
							</Form.Control>
						</Form.Group>

					</div>

				</div>

				<Form.Group className='mb-4'>
					<Form.Label>{text.trusts}*</Form.Label>
				{seriesTrusts.map(t =>
					<Form.Check
						key={t.seriesTrustId}
						type='checkbox'
						name='trusts'
						id={`seriesTrusts-${t.seriesTrustId}`}
						value={t.seriesTrustId}
						checked={values.trusts.indexOf(t.seriesTrustId)>-1}
						onChange={(e) => {
							const isChecked = values.trusts.indexOf(t.seriesTrustId) > -1;
							setFieldValue( 'trusts', isChecked ? values.trusts.filter((e) => {return e !== t.seriesTrustId;}) : [ ...values.trusts,t.seriesTrustId ] );
						}}
						label={<>
							{t.seriesTrustName}
						</>}
					/>
				)}
				</Form.Group>

				<Form.Group>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='form-text'>{text.requiredFields}</p>

			</Form>
		</>)}
		</Formik>
	)

}

export {
	FundForm,
};
