import React, {
	useContext,
} from 'react';


// components
import {
	A,
	Screener,
} from '_components';


// helpers
import {
	ADMIN_USER_COLUMNS,
	navigate,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// services
import {
	userService,
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// List
function List({
	enableFilters=true,
	enableQueryParams=false,
	defaultParams={},
	staticParams={},
	columns=null,
	setUserId=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	// search functions
	const searchOnChange = (selected, searchInput) => {
		if( !selected.length ) return;
		let user = selected[0];
		setUserId(user.userId);
		navigate(`/admin/users/${user.userId}`);
		searchInput.current.clear();
	};

	const searchPreview = (option, props) => (
		<>
			<div className='userFullName'>{option.firstName} {option.lastName}</div>
			<small className='userEmail'>{option.email}</small>
		</>
	);

	// column preferences
	columns = columns || ADMIN_USER_COLUMNS;

	const columnPrefs = JSON.parse( localStorage.getItem( `columns-users` ) || JSON.stringify( [] ) );

	if( columnPrefs.length ) {
		columns = columns.map(column => {
			let pref = columnPrefs.find(f=>f.field===column.field);
			return pref ? { ...column, visible: pref.visible } : { ...column };
		});
	}

	// activate user
	const activate = (user) => {

		// user roles based on WordPress `application_type`
		const activateUser = wordpressService.getUserMeta({
			email: user.email,
			field: 'application_type',
		}).then((response) => {

			userService.activate( user.userId, [response.meta_value || ''] );

		});

		// trigger WP status update
		const triggerStatusUpdate = wordpressService.setUserStatus({
			email: user.email,
			status: 'Approved',
		});

		return Promise.all([activateUser, triggerStatusUpdate]);

	};

	return (<>
		<Screener
			columns={columns}
			primary={'userId'}
			service={{
				...userService,
				delete: (user) => {
					// delete user from web service
					const deleteWebServiceUser = userService.delete(user);

					// delete user from WordPress
					const deleteWordPressUser = wordpressService.deleteUser(user.email);

					return Promise.all([deleteWebServiceUser, deleteWordPressUser]);
				}
			}}
			defaultParams={{
				sortBy: 'createdUtc',
				orderBy: 'DESC',
				...defaultParams,
			}}
			staticParams={{
				...staticParams,
			}}
			enableQueryParams={false}
			queryKey={'users'}
			queryEnabled={!!User.Admin}
			searchEnabled={true}
			searchOnChange={searchOnChange}
			searchPreview={searchPreview}
			fns={{
				activate:activate,
			}}
			toolbar={<>
				<div className='w-100 mb-2 d-sm-none'></div>
				<div className='col-auto ps-sm-2'>
					<A className='btn btn-outline-primary btn-sm' href={`/admin/users/add`} onClick={()=>setUserId('add')}>
						<i className='fas fa-user-plus me-1'></i>
						{text.addUser}
					</A>
				</div>
				<div className='col-auto ps-sm-2'>
					<A className='btn btn-outline-primary btn-sm' href={`/admin/users/upload`} onClick={()=>setUserId('upload')}>
						<i className='fas fa-upload me-1'></i>
						{text.uploadUsers}
					</A>
				</div>
			</>}
			/>
	</>);

}

export { List };
