/*
 * funds service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/funds
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/funds`;


/**
 * GET list of funds
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * GET by fundId
 *
 * @param  string purchaseRequestId
 * @param  object params
 * @return object response
 */
function getById(fundId) {
	return fetchWrapper.get(`${baseUrl}/${fundId}`);
}


/**
 * POST
 *
 * @param  string seriesId
 * @param  object params
 * @return object response
 */
function post(seriesId,params={}) {
	return fetchWrapper.post(`${baseUrl}/${seriesId}`, params);
}


/**
 * Update fund record via PUT
 *
 * @param  string seriesId
 * @param  object params
 * @return object response
 */
function update(seriesId,params={}) {
	return fetchWrapper.put(`${baseUrl}/${seriesId}`, params);
}


/**
 * DELETE fund - accepts string or object with fundId prop
 *
 * @param  object|string props
 * @return object response
 */
function _delete(props) {

	if( 'object' === typeof props ) {

		return fundService.update( props.fundId, {
			...props,
			fundStatusId: 4,
		} );

	}
	else {

		return fetchWrapper.delete(`${baseUrl}/${props}`);

	}

}


// at your service :)
export const fundService = {
	baseUrl,
	get,
	getById,
	post,
	update,
	delete: _delete,
};
