import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// services
import {
	userService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// RolesForm
function RolesForm({
	user={},
	roles=[],
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// form schema
	const schema = yup.object().shape({
		roles: yup.array(),
	});

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors
		setError(false);

		userService.addRoles(user.UserId, values.roles).then((response) => {

			userService.removeRoles(user.UserId, roles.filter((role) => {

				return values.roles.indexOf(role) === -1;

			})).then((response) => {

				setToast(text.rolesUpdated);
				setShowToast(true);

				// reset 'isSubmitting'
				formikBag.setSubmitting(false);

			});

		});

	};
	// user.status
	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<Form.Group className='mb-4'>
				{roles.filter((role) => {return true;}).map(role =>
					<Form.Check
						key={role}
						type='checkbox'
						name='roles'
						id={`role-${role}`}
						disabled={user.RoleNames.indexOf('Unapproved')>-1&&'Approved'!==user.status}
						value={role}
						checked={values.roles.indexOf(role)>-1}
						onChange={(e) => {
							const isChecked = values.roles.indexOf(role) > -1;
							setFieldValue( 'roles', isChecked ? values.roles.filter((e) => {return e !== role;}) : [ ...values.roles,role ] );
						}}
						label={<>
							{role}
						</>}
					/>
				)}
				</Form.Group>

				<Form.Group className='mb-4'>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

			</Form>
		</>)}
		</Formik>
	)

};

export { RolesForm };
