import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
	CountryDropdown,
	RegionDropdown,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// services
import {
	userService,
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// UserForm
function UserForm({
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// form schema
	let fields = {
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		Email: yup.string().email().required(text.invalidEmail),
		phone: yup.string().matches( (new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)), text.invalidPhone ).required(text.invalidPhone),
		address1: yup.string().nullable(),
		address2: yup.string().nullable(),
		cityTown: yup.string().nullable(),
		country: yup.string().nullable(),
		stateProvince: yup.string().nullable(),
		postalCode: yup.string().nullable(),
		website: yup.string().nullable(),
		status: yup.string().nullable(),
	};

	if( !initialValues.UserId ) {
		fields = {
			...fields,
			Password: yup.string().min(6, text.PasswordMinLength.replace('{min', '${min')).matches( (new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/)), text.PasswordStrength).required(text.PasswordRequired),
			ConfirmPassword: yup.string().oneOf([yup.ref('Password'), null], text.PasswordMismatch).required(text.PasswordRequired),
		}
	}

	const schema = yup.object().shape(fields);

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors & toasts
		setError(false);

		// use object destructuring and the spread operator to omit Email from values
		const {Email, Password, ConfirmPassword, ...UserProfile} = values;

		// update user
		if( UserProfile.UserId ) {

			userService.update(UserProfile.UserId, {
				Email: Email,
				UserProfile: JSON.stringify(UserProfile)
			}).then((response) => {
				// trigger status update
				wordpressService.setUserStatus({
					email: Email,
					status: UserProfile.status,
				}).then((response) => {
					// show toast
					setToast(text.profileSaved);
					setShowToast(true);
					// reset 'isSubmitting'
					formikBag.setSubmitting(false);
				});
			});

		}
		// add user
		else {

			wordpressService.apiRequest({
				path: '/userAccounts/register',
				data:  JSON.stringify({
					UserName: Email,
					Email: Email,
					Password: Password,
					ConfirmPassword: ConfirmPassword,
					UserProfile: JSON.stringify(UserProfile),
					RoleNames: [
						'Client',
					],
				})
			}).then((response) => {

				if( response.error ) {

					// set error
					setError(response.error);

					// reset 'isSubmitting'
					formikBag.setSubmitting(false);

					// scroll to top
					window.scrollTo(0, 0);

				}
				else {

					// show toast
					setToast(text.profileSaved);
					setShowToast(true);

				}

			});

		}

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='firstName'>{text.firstName}*</Form.Label>
							<Form.Control
								type='text'
								name='firstName'
								id='firstName'
								value={values.firstName}
								onChange={handleChange}
								isValid={touched.firstName && !errors.firstName}
								isInvalid={touched.firstName && errors.firstName}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='lastName'>{text.lastName}*</Form.Label>
							<Form.Control
								type='text'
								name='lastName'
								id='lastName'
								value={values.lastName}
								onChange={handleChange}
								isValid={touched.lastName && !errors.lastName}
								isInvalid={touched.lastName && errors.lastName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='Email'>{text.Email}*</Form.Label>
							<Form.Control
								type='Email'
								name='Email'
								id='Email'
								value={values.Email}
								onChange={handleChange}
								isValid={touched.Email && !errors.Email}
								isInvalid={touched.Email && errors.Email}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Email}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='phone'>{text.phone}*</Form.Label>
							<Form.Control
								type='tel'
								name='phone'
								id='phone'
								value={values.phone}
								onChange={handleChange}
								isValid={touched.phone && !errors.phone}
								isInvalid={touched.phone && errors.phone}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.phone}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

			<div hidden={true}>
				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='address1'>{text.address1}</Form.Label>
							<Form.Control
								type='text'
								name='address1'
								id='address1'
								value={values.address1}
								onChange={handleChange}
								isValid={touched.address1 && !errors.address1}
								isInvalid={touched.address1 && errors.address1}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='address2'>{text.address2}</Form.Label>
							<Form.Control
								type='text'
								name='address2'
								id='address2'
								value={values.address2}
								onChange={handleChange}
								isValid={touched.address2 && !errors.address2}
								isInvalid={touched.address2 && errors.address2}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='cityTown'>{text.cityTown}</Form.Label>
							<Form.Control
								type='text'
								name='cityTown'
								id='cityTown'
								value={values.cityTown}
								onChange={handleChange}
								isValid={touched.cityTown && !errors.cityTown}
								isInvalid={touched.cityTown && errors.cityTown}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='country'>{text.country}</Form.Label>
							<CountryDropdown
								name='country'
								id='country'
								classes='form-select'
								value={values.country||''}
								valueType='short'
								onChange={(val) => setFieldValue('country', val)}
								/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='stateProvince'>{text.stateProvince}</Form.Label>
							<RegionDropdown
								name='stateProvince'
								id='stateProvince'
								classes='form-select'
								value={values.stateProvince||''}
								valueType='short'
								country={values.country||''}
								countryValueType='short'
								onChange={(val) => setFieldValue('stateProvince', val)}
								/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='postalCode'>{text.postalCode}</Form.Label>
							<Form.Control
								type='text'
								name='postalCode'
								id='postalCode'
								value={values.postalCode}
								onChange={handleChange}
								isValid={touched.postalCode && !errors.postalCode}
								isInvalid={touched.postalCode && errors.postalCode}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='website'>{text.website}</Form.Label>
							<Form.Control
								type='url'
								name='website'
								id='website'
								value={values.website}
								onChange={handleChange}
								isValid={touched.website && !errors.website}
								isInvalid={touched.website && errors.website}
							/>
						</Form.Group>

					</div>

				</div>

			</div>

			{User.Admin && values.RoleNames?.indexOf('Unapproved')>-1 &&
				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='status'>{text.status}</Form.Label>
							<Form.Control
								as='select'
								className='form-select'
								id='status'
								value={values.status}
								onChange={handleChange}
								isValid={touched.status && !errors.status}
								isInvalid={touched.status && errors.status}
							>
							{['in_progress','pending','declined','changes_needed','approved'].map((state) =>
								<option key={state} value={text[state]}>{text[state]}</option>
							)}
							</Form.Control>
						</Form.Group>

					</div>

				</div>
			}

			{!values.UserId &&
				<div className='row mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='Password'>{text.Password}*</Form.Label>
							<Form.Control
								type='password'
								name='Password'
								id='Password'
								autoComplete='off'
								value={values.Password}
								onChange={handleChange}
								isValid={touched.Password && !errors.Password}
								isInvalid={touched.Password && errors.Password}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Password}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='ConfirmPassword'>{text.ConfirmPassword}*</Form.Label>
							<Form.Control
								type='password'
								name='ConfirmPassword'
								id='ConfirmPassword'
								value={values.ConfirmPassword}
								onChange={handleChange}
								isValid={touched.ConfirmPassword && !errors.ConfirmPassword}
								isInvalid={touched.ConfirmPassword && errors.ConfirmPassword}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.ConfirmPassword}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>
			}

				<Form.Group>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='form-text'>{text.requiredFields}</p>

			</Form>
		</>)}
		</Formik>
	)

}

// UserUploadForm
function UserUploadForm({
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// CSV upload
	const [csvFileName, setCsvFileName] = useState('…');

	// form schema
	const schema = yup.object().shape({
		csv: yup.mixed().required(),
	});

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors & toasts
		setError(false);

		wordpressService.uploadUsers({
			csv: values.csv,
		}).then((response) => {

			// show toast
			setToast(response.message);
			setShowToast(true);

			// reset 'isSubmitting'
			formikBag.setSubmitting(false);

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<Form.Group className='mb-3'>
					<Form.Label htmlFor='csv'>
						{`Select a CSV for upload`}
					</Form.Label>
					<Form.Control
						type='file'
						size='sm'
						name='csv'
						id='csv'
						label={csvFileName}
						onChange={(e) => {
							setCsvFileName(e.currentTarget.files[0]?.name||'...');
							setFieldValue('csv', e.currentTarget.files[0]);
						}}
						custom
					/>
				</Form.Group>

				<button type='submit' className='btn btn-sm btn-primary d-block w-100' disabled={isSubmitting}>
					<span>{isSubmitting?text.working:text.uploadUsers}</span>
				</button>

			</Form>
		</>)}
		</Formik>
	)
}

export {
	UserForm,
	UserUploadForm,
};
