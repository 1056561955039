/*
 * Tables string translation
 *
 * @link https://translate.google.com/?sl=en&tl=zh-CN&text=Hello%20world!&op=translate
 *
 */
export const labels = {
	'en': {
		Year: 'Year',
		Jan: 'Jan',
		Feb: 'Feb',
		Mar: 'Mar',
		Apr: 'Apr',
		May: 'May',
		Jun: 'Jun',
		Jul: 'Jul',
		Aug: 'Aug',
		Sep: 'Sep',
		Oct: 'Oct',
		Nov: 'Nov',
		Dec: 'Dec',
		YTD: 'YTD',
		DD: 'DD',
		peak: 'Peak',
		valley: 'Valley',
		runUp: 'Run-up',
		dateStart: 'Start',
		dateEnd: 'End',
	},
};
