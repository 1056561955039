/*
 * assetsManager service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/assetsManager
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/assetsManager`;


/**
 * GET list of assets
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * POST document
 *
 * @param  object params
 * @return object response
 */
function upload(params={}) {
	return fetchWrapper.send(`${baseUrl}/documents`, params);
}


/**
 * DELETE document - accepts string or object with fileId prop
 *
 * @param  object|string props
 * @return object response
 */
function _delete(props) {
	const fileId = 'object' === typeof props ? props.fileId : props;
	return fetchWrapper.delete(`${baseUrl}/${fileId}`);
}


// at your service :)
export const assetsManager = {
	baseUrl,
	get,
	upload,
	delete: _delete,
};
