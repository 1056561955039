// TO-DO? find a better way :/
const platform = {
	siteTitle: 'Galaxy Plus',
};

/*
 * Global string translation
 *
 * @link https://translate.google.com/?sl=en&tl=zh-CN&text=Hello%20world!&op=translate
 *
 */
export const labels = {
	'en': {
		siteTitle: platform.siteTitle,
		titleSeparator: ' | ',

		accountLogIn: 'Account Login',
		admin: 'Admin',
		dashboard: 'Dashboard',
		platform: 'Platform',
		profile: 'Profile',
		logIn: 'Log In',
		logOut: 'Log Out',
		register: 'Register',
		purchases: 'Purchases',
		purchase: 'Purchase',
		purchaseFunds: 'Purchase Funds',
		purchaseSaved: 'Purchase Saved',
		addSeriesTrust: 'Add Series Trust',
		seriesTrusts: 'Trusts',

		loading: <><i className='fas fa-circle-notch fa-spin me-1'></i>{`loading…`}</>,
		working: <><i className='fas fa-circle-notch fa-spin me-1'></i>{`working…`}</>,

		errorMessage: 'Something went wrong…',
		pageNotFound: 'Page Not Found',

		page: 'Page',
		of: 'of',
		or: 'or',
		to: 'to',
		rows: 'Rows',
		showAll: 'Show All',
		viewMore: 'View More',
		filters: 'Filters',

		companies: 'Companies',
		company: 'Company',
		companyName: 'Company Name',
		addCompany: 'Add Company',

		fund: 'Fund',
		funds: 'Funds',
		fundName: 'Fund Name',
		addFund: 'Add Fund',

		feederFund: 'Feeder Fund',

		trust: 'Trust',
		trusts: 'Trusts',

		users: 'Users',
		addUser: 'Add User',
		uploadUsers: 'Upload Users',

		selectDifferentTrust: 'Select a different trust',
		selectSeriesTrust: 'Select Series Trust…',
		seriesFId: 'Strategy ID',
		seriesName: 'Series Name',
		strategies: 'Strategies',
		strategyName: 'Strategy Name',
		addStrategy: 'Add Strategy',

		activate: 'activate',
		userActivated: 'User activated',

		approved: 'Approved',

		monthly: 'Monthly',
		annual: 'Annual',

		confirmDelete: 'Are you sure you want to delete this?',
		confirmCancel: 'Are you sure you want to cancel?',
		yes: 'Yes',
		no: 'No',
		cancel: 'Cancel',
		deleted: 'Deleted',

		location: 'Location',
		logs: 'Logs',

		saved: 'Saved',
		unsavedChanges: 'Notice: Unsaved Changes',

		PDF: 'PDF',
		downloadPDF: 'Download PDF',
		generatingPDF: 'Generating PDF…',
	},
};
