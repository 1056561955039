// components
import {
	ToastContainer,
	Toast,
} from '_components';


// Notifications
function Notifications({
	error=false,
	success=false,
	toast='',
	toastDelay=3000,
	showToast=false,
	setShowToast=()=>{},
}) {

	const toggleToast = () => setShowToast(!showToast);

	return (<>
		<div className='alert alert-danger mt-3' hidden={!error}>
			{error}
		</div>

		<div className='alert alert-success mt-3' hidden={!success}>
			{success}
		</div>

		<div aria-live='polite' aria-atomic='true' className='position-relative'>

			<ToastContainer className='mt-n4' position='top-center'>

				<Toast
					className='d-flex text-dark'
					bg='light'
					show={showToast}
					onClose={toggleToast}
					delay={toastDelay}
					autohide={true}>

					<Toast.Body>{toast}</Toast.Body>

					<button type='button' className='btn-close btn-close-dark me-2 m-auto' aria-label={`Close`} onClick={toggleToast}></button>

				</Toast>

			</ToastContainer>

		</div>
	</>)

}

export { Notifications };
