import React, {
	useContext,
	useRef,
	useState,
} from 'react';


// components
import {
	AsyncTypeahead,
	Actions,
	ConfirmModal,
	Filters,
	Notifications,
	Pagination,
	Table,
} from '_components';


// helpers
import {
	useQueries,
	useQueryParams,
} from '_helpers';


// helpers
import {
	assetsManager,
} from '_services';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


function Screener({
	columns=[],
	primary,
	service={
		get:null,
		delete:null,
	},
	defaultParams={},
	staticParams={},
	enableFilters=true,
	enableQueryParams=false,
	queryEnabled=true,
	queryKey='',
	searchEnabled=false,
	searchOnChange=()=>{},
	searchPreview=()=>{},
	fns={
		activate:()=>{},
		updateProperty:()=>{},
		updatePropertyCallback:()=>{},
	},
	toolbar=null,
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	const [cols, setCols] = useState(columns);
	const [rows, setRows] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [selectedRows, setSelectedRows] = useState([]);


	// queryParams
	const [queryParams, setQueryParams] = useQueryParams();

	// make sure our defaults make sense
	const defaults = {
		pageNumber: 1,
		pageSize: 50,
		...defaultParams,
		...staticParams,
		...enableQueryParams === true ? queryParams : {},
	};

	// screener params
	const [params, setParams] = useState(defaults);
	const [queryFnParams, setQueryFnParams] = useState(defaults);
	const [doingAsync, setDoingAsync] = useState(false);

	// updateParams
	const updateParams = (obj={},updateQueryParams={}) => {

		// update queryParams
		if( enableQueryParams === true ) setQueryParams({...obj,...updateQueryParams});

		// merge new params with old
		obj = {
			...params,
			...obj,
		};

		// update screener params
		setParams(obj);

		// add static parameters
		obj = {
			...obj,
			...staticParams,
		};

		// update params for query
		setQueryFnParams(obj);

	};


	// selectRow
	const selectRow = (selected) => {

		let updatedRows = rows.map(row => {
			return row === selected ? { ...row, selected: !row.selected } : { ...row};
		});

		setRows(updatedRows);
		setSelectedRows(updatedRows.filter((r) => !!r.selected));

	};


	// selectAll
	const selectAll = (selected) => {

		let updatedRows = rows.map(row => {
			return { ...row, selected: selected };
		});

		setRows(updatedRows);
		setSelectedRows(selected ? updatedRows : []);

	};


	// search functions
	const [isSearching, setIsSearching] = useState(false);
	const searchInput = useRef(null);
	const handleSearch = (query) => {
	    setIsSearching(true);
		updateParams({
			search: query,
			pageNumber: 1,
		});
	};


	// toasts
	const [toast, setToast] = useState(queryParams?.saved?text.saved:'');
	const [showToast, setShowToast] = useState(queryParams?.saved?true:false);


	// CRUD functions
	const [row, setRow] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	const cancelDelete = () => {
		setRow(false);
		setShowConfirm(false);
	};

	const confirmDelete = (row) => {
		setRow(row);
		setShowConfirm(true);
	};

	const _deleteRow = () => {

		if( !row ) return;

		service.delete(row).then((response) => {

			setRows( rows.filter((r) => r !== row) );

			setToast( response?.message || text.deleted );
			setShowToast(true);

			setShowConfirm(false);

		});

	};


	// useQueries
	const [
		{
			isLoading,
		},
	] = useQueries([
		{
			queryKey: [queryKey, queryFnParams],
			queryFn: () => service.get(queryFnParams),
			onSuccess: (response) => {
				setIsSearching( false );
				switch( queryKey ) {
					case 'purchases':
						setDoingAsync(true);
						const getPurchasesWithPDFs = async () => {
							const purchases = response.items;
							for (let purchase of purchases) {
								const assetsRequest = await assetsManager.get({filterByEntityId:purchase.purchaseRequestId});
								purchase.pdf = assetsRequest?.items[0]?.uri || false;
								purchase.selected = false;
							}
							return purchases;
						};
						getPurchasesWithPDFs().then((response) => {
							setRows(response);
							setDoingAsync(false);
						});
					break;
					case 'series':
						setRows( response.items.filter((v,i,a)=>a.findIndex(v2=>(v2[primary]===v[primary]))===i).map(row => {
							return { ...row, selected: false };
						}) );
					break;
					default:
						setRows( response.items.map(row => {
							return { ...row, selected: false };
						}) );
				}
				setTotalCount( response.totalCount );
				setTotalPages( Math.ceil(response.totalCount / queryFnParams.pageSize) );
			},
			enabled: 'function' === typeof service.get && queryEnabled
		},
	]);


	return (<>
		<ConfirmModal
			showConfirm={showConfirm}
			handleCloseConfirm={cancelDelete}
			confirmCallback={_deleteRow}
			/>

		<Notifications
			toast={toast}
			showToast={showToast}
			setShowToast={setShowToast}
			/>

		<Table
			cols={cols}
			rows={rows}
			small={false}
			sticky={true}
			primary={primary}
			isLoading={isLoading || doingAsync}
			removeRow={confirmDelete}
			selectRow={selectRow}
			selectAll={selectAll}
			fns={fns}
			toolbar={
				<div className={'d-flex align-items-center bg-light border-' + (!!rows.length ? 'bottom' : 'none')}>
				{searchEnabled &&
					<div className='col-12 col-sm-auto py-3'>

						<div className='async-typeahead-container position-relative'>

							<AsyncTypeahead
								size='sm'
								filterBy={()=>true}
								id='searchInput'
								isLoading={isSearching}
								labelKey={(option) => `${option.firstName} ${option.lastName}`}
								ref={searchInput}
								delay={500}
								minLength={3}
								onChange={(selected) => {searchOnChange(selected,searchInput)}}
								onSearch={handleSearch}
								options={rows}
								searchText={`Searching…`}
								placeholder={`Search…`}
								useCache={false}
								renderMenuItemChildren={searchPreview}
								/>

							<button className='btn btn-sm btn-x'
								hidden={searchInput && searchInput.current && !searchInput.current.state.text && !queryFnParams.search}
								disabled={false}
								onClick={(e) => {
									e.preventDefault();
									searchInput.current.clear();
									updateParams({
										search: '',
										pageNumber: 1,
									});
									e.currentTarget.blur();
								}}>
								<i className='fas fa-times-circle'></i>
							</button>

						</div>

					</div>
				}
					{toolbar}

					<Actions
						model={queryKey}
						selectAll={selectAll}
						selectedRows={selectedRows}
						setToast={setToast}
						/>

					<div className='w-100 mb-2 d-lg-none'></div>

				{totalPages > 1 &&
					<div className='col-10 col-sm-auto ms-lg-auto py-3 px-2'>

						<Pagination
							lang={lang}
							params={{
								...queryFnParams,
								totalCount,
								totalPages,
							}}
							setParams={updateParams}
							/>

					</div>
				}
				{User.isLoggedIn && enableFilters &&
					<div className={'col-auto ms-auto ms-lg-' + (totalPages > 1 ? 0 : 'auto') + ' py-3'}>

						<Table.Options
							model={queryKey}
							columns={cols}
							setColumns={setCols}
							>

							<Filters
								model={queryKey}
								params={queryFnParams}
								setParams={updateParams}
								/>

						</Table.Options>

					</div>
				}
				</div>
			}
			params={queryFnParams}
			setParams={updateParams}
			/>
	</>);

}

export { Screener };
