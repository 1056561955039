import React, {
	useContext,
	useEffect,
	useState,
} from 'react';


// components
import {
	A,
	FileList,
	PurchaseList,
	CompanyForm,
	CompanyList,
	FundForm,
	SeriesTrustForm,
	SeriesTrustList,
	StrategyForm,
	StrategyList,
	UserForm,
	UserList,
	UserUploadForm,
	EditPasswordForm,
	FileUploadForm,
	RolesForm,
	Screener,
	Tabs,
	Tab,
} from '_components';


// helpers
import {
	ADMIN_FUND_COLUMNS,
	navigate,
	useQueries,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// services
import {
	fundService,
	seriesTrustService,
	strategyService,
	companyService,
	userService,
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsAdmin } from '_i18n/Admin';
import { labels as labelsForms } from '_i18n/Forms';


function Admin({
	tab='users',
	id=null,
	sub='profile',
	sid=null,
	gub='profile',
}) {

	// User
	const User = useContext(UserModel);


	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsAdmin[lang], ...labelsForms[lang]};


	// data containers for query results
	const [roles, setRoles] = useState( JSON.parse( localStorage.getItem( `user-roles` ) || JSON.stringify( [] ) ) );
	const [companies, setCompanies] = useState( JSON.parse( JSON.stringify( [] ) ) );

	const [currentTab, setCurrentTab] = useState(tab);
	const [subTab, setSubTab] = useState(sub);
	const [gubTab, setGubTab] = useState(gub);

	const [companyId, setCompanyId] = useState(id);
	const [fundId, setFundId] = useState(sid);
	const [seriesId, setSeriesId] = useState(id);
	const [seriesTrustId, setSeriesTrustId] = useState(id);
	const [userId, setUserId] = useState(id);

	const [isEditing, setIsEditing] = useState(id !== null);

	const [editingCompany, setEditingCompany] = useState(false);
	const [editingStrategy, setEditingStrategy] = useState(false);
	const [editingTrust, setEditingTrust] = useState(false);
	const [editingFund, setEditingFund] = useState(false);
	const [editingUser, setEditingUser] = useState(false);

	const [documents, setDocuments] = useState([]);
	const [showFileList, setShowFileList] = useState(true);


	// access + redirects
	useEffect(() => {
		if( !User.Admin ) {
			window.location = '/';
		}
		else if( !User.isLoggedIn ) {
			window.location = tab ? `/login?redirect=/admin/${tab}` : `/login?redirect=/admin`;
		}
	}, [
		User,
		tab,
	]);


	// run our queries
	const [
		{
			isLoading: rolesLoading,
		},
		{
			isLoading: strategyLoading,
		},
		{
			isLoading: userLoading,
		},
		{
			isLoading: companyLoading,
		},
		{
			isLoading: seriesTrustLoading,
		},
	] = useQueries([
		{
			queryKey: ['roles'],
			queryFn: () => userService.getRoles(),
			onSuccess: (response) => {

				const roles = User.Admin ? [
					'Administrator',
					...response.roles.sort(),
				] : response.roles;

				setRoles(roles);

				localStorage.setItem( 'user-roles', JSON.stringify( roles ) );

			},
			enabled: !!(User.Admin) && !roles.length
		},
		{
			queryKey: ['seriesId', {
				filterBySeriesId: seriesId,
			}],
			queryFn: () => strategyService.getById(seriesId),
			onSuccess: (response) => {

				setEditingStrategy( response );
				setEditingFund( response.funds.find(f => f.fundId === sid) || false );

			},
			enabled: !!(User.Admin) && ('strategies' === tab) && !!(seriesId && 'add' !== seriesId)
		},
		{
			queryKey: ['user', {
				filterByUserId: userId,
			}],
			queryFn: () => userService.get({
				filterByUserId: userId,
			}),
			onSuccess: (response) => {

				userService.getByEmail( response.items[0].email ).then((response) => {

					setEditingUser( userService.parse(response) );

				});

			},
			enabled: !!(User.Admin) && ('users' === tab) && !!(userId && 'add' !== userId)
		},
		{
			queryKey: ['company', companyId],
			queryFn: () => companyService.getById(companyId),
			onSuccess: (response) => {

				setEditingCompany( response );

			},
			enabled: !!(User.Admin) && ('companies' === tab) && !!(companyId && 'add' !== companyId)
		},
		{
			queryKey: ['seriesTrust', seriesTrustId],
			queryFn: () => seriesTrustService.getById(seriesTrustId),
			onSuccess: (response) => {

				setEditingTrust( response );

			},
			enabled: !!(User.Admin) && ('trusts' === tab) && !!(seriesTrustId && 'add' !== seriesTrustId)
		},
		{
			queryKey: ['companies'],
			queryFn: () => companyService.get({
				filterByCompanyStatusId: 1,
				sortBy: 'companyName',
				orderBy: 'ASC',
				pageSize: 500,
			}),
			onSuccess: (response) => {

				setCompanies(response.items);

				localStorage.setItem( 'companies', JSON.stringify( response.items ) );

			},
			enabled: !!(User.Admin) && !companies.length
		},
		{
			queryKey: ['documents', {
				email: editingUser.Email,
			}],
			queryFn: () => wordpressService.getUserDocs({
				email: editingUser.Email,
			}),
			onSuccess: (response) => {

				setDocuments( response?.items || [] );

			},
			enabled: !!(User.Admin) && !!(editingUser)
		},
	]);


	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});


	return User.isLoggedIn && User.Admin && (<>
		<h1 className='m-0 py-5'>{text.pageTitle}</h1>

		<Tabs
			className={'galaxy-tabs'}
			defaultActiveKey={tab}
			onSelect={(k) => {
				navigate(`/admin/${k}`);
				setCompanyId(null);
				setFundId(null);
				setSeriesId(null);
				setSeriesTrustId(null);
				setUserId(null);
				setIsEditing(false);
				setCurrentTab(k);
			}}>

			<Tab className='py-5' eventKey='users' title={text.users}>

			{!userLoading && !!userId && 'add' !== userId && !!editingUser && <>

				<h2 className='mb-4 text-center'>{`Editing ${editingUser.firstName} ${editingUser.lastName}`}</h2>

				<Tabs
					className={'galaxy-tabs justify-content-center'}
					defaultActiveKey={'profile'}>

					<Tab
						className='form-narrow'
						eventKey='profile'
						title={text.profile}>

						<UserForm
							initialValues={editingUser}
							/>

					</Tab>

					<Tab
						className='form-narrow'
						eventKey='password'
						title={text.Password}>

						<EditPasswordForm
							initialValues={{
								Email: editingUser.Email,
								NewPassword: '',
								PasswordConfirmation: '',
							}}
							/>

					</Tab>

					<Tab
						className='form-narrow'
						eventKey='roles'
						title={text.Roles}>

					{!rolesLoading &&

						<RolesForm
							user={editingUser}
							roles={roles}
							initialValues={{
								roles: editingUser.RoleNames,
							}}
							/>

					}

					</Tab>

				{!!documents.length &&
					<Tab
						className='form-narrow'
						eventKey='documents'
						title={text.Documents}>

						<div className='list-group my-5'>
						{documents.map((file) =>
							<a key={file.ID} target='_blank' rel='noreferrer' href={file.url} className='list-group-item list-group-item-action'>
								<i className='fa-solid fa-fw fa-file me-1'></i>
								<span>{file.label}</span>
							</a>
						)}
						</div>

					</Tab>
				}

					<Tab
						eventKey='files'
						title={text.Files}>

						<FileList
							staticParams={{
								filterByEntityId: editingUser.UserGuid,
							}}
							/>

					</Tab>

				</Tabs>

			</>}

			{ 'add' === userId && <>

				<div className='form-narrow'>

					<h2>{text.addUser}</h2>

					<UserForm
						initialValues={{
							firstName: '',
							lastName: '',
							Email: '',
							phone: '',
							Password: '',
							ConfirmPassword: '',
						}}
						/>

				</div>

			</>}

			{ 'upload' === userId && <>

				<div className='form-narrow'>

					<h2>{text.uploadUsers}</h2>

					<UserUploadForm />

				</div>

			</>}

			{!userId && !isEditing &&

				<UserList
					setUserId={setUserId}
					/>

			}
			</Tab>

			<Tab className='py-5' eventKey='companies' title={text.companies}>

			{!companyLoading && !!companyId && 'add' !== companyId && !!editingCompany && <>

				<h2 className='mb-4 text-center'>{`Editing ${editingCompany.companyName}`}</h2>

				<div className='form-narrow'>

					<CompanyForm
						initialValues={editingCompany}
						/>

				</div>

			</>}

			{ 'add' === companyId && <>

				<div className='form-narrow'>

					<h2>{text.addCompany}</h2>

					<CompanyForm
						initialValues={{
							companyName: '',
						}}
						/>

				</div>

			</>}

			{!companyId && !isEditing &&

				<CompanyList
					setCompanyId={setCompanyId}
					/>

			}
			</Tab>

			<Tab className='py-5' eventKey='strategies' title={text.strategies}>

			{!!editingStrategy && !!editingFund && !!fundId && <>

				<h2 className='mb-4 text-center'>
					<span className='pe-2'>{`Editing`}</span>
					<a href={`/admin/strategies/${seriesId}/profile`}>{`${editingStrategy.strategyName}`}</a>
					<i className='fa-solid fa-chevron-right fa-2xs px-2'></i>
					<span>{`${editingFund.fundName}`}</span>
				</h2>

				<Tabs
					className={'galaxy-tabs justify-content-center'}
					defaultActiveKey={gubTab}
					onSelect={(k) => {
						navigate(`/admin/strategies/${seriesId}/funds/${fundId}/${k}`);
						setGubTab(k);
					}}>

					<Tab
						className=''
						eventKey='profile'
						title={text.profile}>

						{'profile' === gubTab && !! editingFund &&

							<div className='form-narrow'>

								<FundForm
									initialValues={{
										...editingFund,
										seriesId: seriesId,
									}}
									seriesTrusts={editingStrategy.seriesTrusts}
									/>

							</div>

						}

					</Tab>

					<Tab
						className=''
						eventKey='files'
						title={text.Files}>

						<div className='row'>

							<div className='col col-lg-9'>
							{!!showFileList &&
								<FileList
									staticParams={{
										filterByEntityId: fundId,
									}}
									/>
							}
							</div>

							<div className='col-12 col-lg-3'>

								<FileUploadForm
									initialValues={{
										entityId: fundId,
										filesTypeId: '70b0fbbc-edc7-41c6-830d-2d1d44b75554',
									}}
									refreshFiles={()=>{
										setShowFileList(false);
										setTimeout(() => {
											setShowFileList(true);
										}, 50);
									}}
									/>

							</div>

						</div>

					</Tab>

				</Tabs>

			</>}
			{!strategyLoading && !!seriesId && 'add' !== seriesId && !!editingStrategy && !editingFund && <>

				<h2 className='mb-4 text-center'>{`Editing ${editingStrategy.strategyName}`}</h2>

				<Tabs
					className={'galaxy-tabs justify-content-center'}
					defaultActiveKey={subTab}
					onSelect={(k) => {
						navigate(`/admin/strategies/${seriesId}/${k}`);
						setEditingFund(null);
						setFundId(null);
						setSubTab(k);
					}}>

					<Tab
						className=''
						eventKey='profile'
						title={text.profile}>

						<div className='form-narrow'>
						{'profile' === subTab && ! fundId &&

							<StrategyForm
								initialValues={editingStrategy}
								/>

						}
						</div>

					</Tab>

					<Tab
						className=''
						eventKey='funds'
						title={text.funds}>
					{'funds' === subTab && ! fundId && <>

						<Screener
							columns={ADMIN_FUND_COLUMNS.map((c) =>
								{
									return 'fundId' === c.field ? {
										...c,
										seriesId: seriesId,
									} : c;
								})
							}
							primary={'fundId'}
							service={{
								...fundService,
								get: () => {
									return {
										items: editingStrategy.funds,
										totalCount: editingStrategy.funds?.length || 0,
									};
								}
							}}
							enableQueryParams={false}
							queryKey={'funds'}
							queryEnabled={true}
							searchEnabled={false}
							toolbar={<>
								<div className='d-flex align-items-center bg-light border-bottom'>
									<div className='w-100 mb-2 d-sm-none'></div>
									<div className='col-auto ps-sm-0 py-3'>
										<A className='btn btn-outline-primary btn-sm' href={`/admin/strategies/${seriesId}/funds/add`} onClick={()=>setFundId('add')}>
											<i className='fas fa-plus me-1'></i>
											{text.addFund}
										</A>
									</div>
								</div>
							</>}
							/>

					</>}

					{'funds' === subTab && 'add' === fundId &&

						<div className='form-narrow'>

							<FundForm
								initialValues={{
									fundName: '',
									fundingFactorMax: '',
									fundStatusId: 1,
									trusts: [],
									seriesTrusts: [],
									seriesId: seriesId,
								}}
								seriesTrusts={editingStrategy.seriesTrusts}
								/>

						</div>

					}
					</Tab>

					<Tab
						className=''
						eventKey='files'
						title={text.Files}>

						<div className='row'>

							<div className='col col-lg-9'>
							{!!showFileList &&
								<FileList
									staticParams={{
										filterByEntityId: seriesId,
									}}
									/>
							}

							</div>

							<div className='col-12 col-lg-3'>

								<FileUploadForm
									initialValues={{
										entityId: seriesId,
										filesTypeId: 'b715b80d-7689-4336-bb61-38dd9802b8fe',
									}}
									refreshFiles={()=>{
										setShowFileList(false);
										setTimeout(() => {
											setShowFileList(true);
										}, 50);
									}}
									/>

							</div>

						</div>

					</Tab>

				</Tabs>

			</>}

			{ 'add' === seriesId && <>

				<div className='form-narrow'>

					<h2>{text.addStrategy}</h2>

					<StrategyForm
						initialValues={{
							seriesName: '',
							strategyName: '',
							seriesStatusId: 1,
							companyId: '',
							seriesTrusts: [],
						}}
						/>

				</div>

			</>}

			{!seriesId && !isEditing &&

				<StrategyList
					setSeriesId={setSeriesId}
					/>

			}
			</Tab>

			<Tab className='py-5' eventKey='purchases' title={text.purchases}>
			{('purchases' === tab || 'purchases' === currentTab) &&

				<PurchaseList
					/>

			}
			</Tab>

			<Tab className='py-5' eventKey='trusts' title={text.seriesTrusts}>

			{!seriesTrustLoading && !!seriesTrustId && 'add' !== seriesTrustId && !!editingTrust && <>

				<h2 className='mb-4 text-center'>{`Editing ${editingTrust.seriesTrustName}`}</h2>

				<Tabs
					className={'galaxy-tabs justify-content-center'}
					defaultActiveKey={subTab}
					onSelect={(k) => {
						navigate(`/admin/trusts/${seriesTrustId}/${k}`);
						setSubTab(k);
					}}>

					<Tab
						className=''
						eventKey='profile'
						title={text.profile}>

						<div className='form-narrow'>
						{'profile' === subTab &&

							<SeriesTrustForm
								initialValues={editingTrust}
								/>

						}
						</div>

					</Tab>

					<Tab
						className=''
						eventKey='files'
						title={text.Files}>

						<div className='row'>

							<div className='col col-lg-9'>
							{!!showFileList &&
								<FileList
									staticParams={{
										filterByEntityId: seriesTrustId,
									}}
									/>
							}
							</div>

							<div className='col-12 col-lg-3'>

								<FileUploadForm
									initialValues={{
										entityId: seriesTrustId,
										filesTypeId: '70b0fbbc-edc7-41c6-830d-2d1d44b75554',
									}}
									refreshFiles={()=>{
										setShowFileList(false);
										setTimeout(() => {
											setShowFileList(true);
										}, 50);
									}}
									/>

							</div>

						</div>

					</Tab>

				</Tabs>

			</>}

			{!seriesTrustId && ('trusts' === tab || 'trusts' === currentTab) &&

				<SeriesTrustList
					setSeriesTrustId={setSeriesTrustId}
					/>

			}
			</Tab>

		</Tabs>

	</>);
}

export { Admin };
