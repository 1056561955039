import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// services
import {
	assetsManager,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// FileUploadForm
function FileUploadForm({
	initialValues={},
	refreshFiles=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// form schema
	const schema = yup.object().shape({
		file: yup.mixed().required(),
		filesTypeId: yup.string().required(),
	});

	// file
	const [fileName, setFileName] = useState('');

	// submit handler
	const onSubmit = (values, formikBag) => {

		assetsManager.upload({
			file: values.file,
			filesTypeId: values.filesTypeId,
			entityId: values.entityId,
			title: values.file.name,
			isPublic: true,
			isSecure: false,
		}).then((response) => {

			if( response.error ) {

				setError( text[response.error] ? text[response.error] : response.error );

			}
			else {

				setToast(`file uploaded`);
				setShowToast(true);
				setFileName('');

				formikBag.setFieldValue( 'file', '' );
				formikBag.setFieldValue( 'title', '' );
				formikBag.resetForm({
					values: {
						file: '',
						filesTypeId: values.filesTypeId,
						entityId: values.entityId,
						title: '',
					}
				});

				refreshFiles();

			}

			// reset 'isSubmitting'
			formikBag.setSubmitting(false);

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<Form.Group className='mb-3'>
					<Form.Label htmlFor='file'><span className='badge bg-dark text-light'>{`Upload File`}</span></Form.Label>
					<Form.Control
						type='file'
						name='file'
						id='file'
						className='form-control-sm'
						label={fileName}
						onChange={(e) => {
							setFileName( e.currentTarget?.files[0]?.name );
							setFieldValue( 'file', e.currentTarget?.files[0] );
						}}
					/>
				</Form.Group>

				<Form.Group className='mb-3'>
					<Form.Label htmlFor='filesTypeId'><span className='badge bg-dark text-light'>{`Type`}</span></Form.Label>
					<Form.Control
						as='select'
						className='form-select form-select-sm'
						id='filesTypeId'
						value={values.filesTypeId}
						onChange={handleChange}
						isValid={touched.filesTypeId && !errors.filesTypeId}
						isInvalid={touched.filesTypeId && errors.filesTypeId}
					>
					{[
						{
							'filesTypeId': '76a0dd0d-2c93-4ebf-a624-87e02b4bc0b1',
							'filesTypeDescription': 'Application Data'
						},
						{
							'filesTypeId': '70b0fbbc-edc7-41c6-830d-2d1d44b75554',
							'filesTypeDescription': 'Fund Memorandum'
						},
						{
							'filesTypeId': '84a2b108-8dc5-4bfa-a9e1-1519ac8974d2',
							'filesTypeDescription': 'Purchase Order'
						},
						{
							'filesTypeId': 'b715b80d-7689-4336-bb61-38dd9802b8fe',
							'filesTypeDescription': 'Tearsheet'
						},
					].map((type) =>
						<option key={type.filesTypeId} value={type.filesTypeId}>{type.filesTypeDescription}</option>
					)}
					</Form.Control>

				</Form.Group>

				<Form.Group className='mb-3'>
					<button type='submit' className='btn btn-primary btn-sm d-block w-100' disabled={isSubmitting||!values.file}>
						<i className='fas fa-circle-notch fa-spin me-1' hidden={!isSubmitting}></i>
						<span>{`Upload`}</span>
					</button>
				</Form.Group>

			</Form>
		</>)}
		</Formik>
	)

};

export { FileUploadForm };
