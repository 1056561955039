import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// services
import {
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';
import { labels as labelsLogin } from '_i18n/Login';


// ForgotPasswordForm
function ForgotPasswordForm() {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsLogin[lang]};

	// error handling
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	// form schema
	const schema = yup.object().shape({
		email: yup.string().email().required(text.invalidEmail),
	});

	// submit handler
	const onSubmit = (values, formikBag) => {

		setSuccess(false);
		setError(false);

		// make API call
		wordpressService.apiRequest({
			method: 'GET',
			path: `/userAccounts/getforgotpasswordtoken/?email=` + encodeURIComponent(values.email),
			lang: lang,
			email: values.email,
		}).then((response) => {

			response.status === 404 ? setError(text.emailNotFound) : setSuccess(text.emailFound);

			// reset 'isSubmitting'
			formikBag.setSubmitting(false);

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={{
				email: '',
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				success={success}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<Form.Group className='mb-3'>
					<Form.Label htmlFor='email'>{text.Email}</Form.Label>
					<Form.Control
						type='email'
						name='email'
						id='email'
						value={values.email}
						onChange={handleChange}
						isValid={touched.email && !errors.email}
						isInvalid={touched.email && errors.email}
					/>
					<Form.Control.Feedback type='invalid'>
						{errors.email}
					</Form.Control.Feedback>
				</Form.Group>

				<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
					<span>{text.submit}</span>
				</button>

				<a className='btn btn-outline-primary d-block w-100 mt-3' href={`/login`}>{text.login}</a>

			</Form>
		</>)}
		</Formik>
	)
}

export { ForgotPasswordForm };
