import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// services
import {
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';
import { labels as labelsLogin } from '_i18n/Login';


// ResetPasswordForm
function ResetPasswordForm({
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsLogin[lang]};

	// error handling
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	// form schema
	const schema = yup.object().shape({
		Email: yup.string().email().required(text.invalidEmail),
		NewPassword: yup.string().min(6, text.PasswordMinLength.replace('{min', '${min')).matches( (new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/)), text.PasswordStrength).required(text.PasswordRequired),
		PasswordConfirmation: yup.string().oneOf([yup.ref('NewPassword'), null], text.PasswordMismatch).required(text.PasswordRequired),
	});

	// submit handler
	const onSubmit = (values, formikBag) => {

		setSuccess(false);
		setError(false);

		// make API call
		wordpressService.apiRequest({
			path: '/userAccounts/resetpasswordwithtoken',
			data:  JSON.stringify(values),
		}).then((response) => {

			if( response.error ) {

				setError( text[response.error] ? text[response.error] : response.error );

			}
			else if( response.status === 404 ) {

				setError(text.errorMessage);

			}
			else {

				setSuccess(text.passwordReset);

				setTimeout(() => {
					window.location = `/login`;
				}, 3000);

			}

			// reset 'isSubmitting'
			formikBag.setSubmitting(false);

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				success={success}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='Email'>{text.Email}*</Form.Label>
							<Form.Control
								type='email'
								name='Email'
								id='Email'
								value={values.Email}
								onChange={handleChange}
								isValid={touched.Email && !errors.Email}
								isInvalid={touched.Email && errors.Email}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Email}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<div className='row gx-3 mb-4'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='NewPassword'>{text.NewPassword}*</Form.Label>
							<Form.Control
								type='password'
								name='NewPassword'
								id='NewPassword'
								autoComplete='off'
								value={values.NewPassword}
								onChange={handleChange}
								isValid={touched.NewPassword && !errors.NewPassword}
								isInvalid={touched.NewPassword && errors.NewPassword}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.NewPassword}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='PasswordConfirmation'>{text.PasswordConfirmation}*</Form.Label>
							<Form.Control
								type='password'
								name='PasswordConfirmation'
								id='PasswordConfirmation'
								value={values.PasswordConfirmation}
								onChange={handleChange}
								isValid={touched.PasswordConfirmation && !errors.PasswordConfirmation}
								isInvalid={touched.PasswordConfirmation && errors.PasswordConfirmation}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.PasswordConfirmation}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<Form.Group className='mb-4'>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='form-text'>{text.requiredFields}</p>

			</Form>
		</>)}
		</Formik>
	)

};

export { ResetPasswordForm };
