import React, {
	useContext,
	useEffect,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// PurchaseForm
function PurchaseForm({
	funds=[],
	setFundId=()=>{},
	setInitialValues=()=>{},
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// funds
	const [fund, setFund] = useState( funds.find(fund => fund.fundId === initialValues.fundId) );
	const [fundingFactors, setFundingFactors] = useState( [] );

	// form schema
	let fields = {
		fundId: yup.string().required(),
		amount: yup.number().min(100000).required(),
		investmentClass: yup.string().required(),
		fundingFactorEffective: yup.number().min(1).max(fund?.fundingFactorMax||1).required(),
	};

	const schema = yup.object().shape(fields);

	// fund updates
	useEffect(() => {
		setFundingFactors( () => {
			let factors = [];
			for( let i = (fund?.fundingFactorMax||1); i >= 1; i-- ) {
				factors.push(i);
				i!==1&&factors.push(i-.5);
			}
			return factors.reverse();
		} );
	}, [
		fund,
	]);

	// reset form
	const resetForm = () => {
		setInitialValues(null);
		setFundId(null);
	};

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors & toasts
		setError(false);

		// load purchaseRequest from localStorage if possible
		let purchaseItems = JSON.parse( localStorage.getItem('purchaseItems') || JSON.stringify([]) );

		// reset disclosures
		localStorage.removeItem( 'disclosureHTML' );
		localStorage.removeItem( 'disclosureList' );

		// modifying, so remove the original
		if( values.tempId ) {
			purchaseItems = purchaseItems.filter(item => item.tempId !== values.tempId);
		}

		// set purchaseRequest in localStorage
		localStorage.setItem( 'purchaseItems', JSON.stringify([
			...purchaseItems,
			{
				tempId: (new Date()).getTime(),
				...values,
				...fund,
			}
		]) );

		// reset
		resetForm();

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<p>{`The below purchase information is submitted by the Subscriber to indicate into which of the Funds the Subscriber wishes to invest.`}</p>

				<p>{`Please indicate, in U.S. dollars, the amount of each Fund you would like to purchase and if you would like an Effective Funding Factor of a lesser amount than the listed Maximum Funding Factor.`}</p>

				<div className={'card border-0 mb-4'}>

					<div className={'card-body p-4'}>

						<div className={'row mb-3'}>

							<div className={'col'}>

								<Form.Group>
									<Form.Label htmlFor='fundId'>{text.selectFund}</Form.Label>
									<Form.Control
										as='select'
										className='form-select'
										id='fundId'
										value={values.fundId}
										onChange={(e) => {
											let fundId = e.currentTarget.value;
											let currentFund = funds.find(fund => fund.fundId === fundId);
											setFund(currentFund);
											setFieldValue('fundId', fundId);
										}}
										isValid={touched.fundId && !errors.fundId}
										isInvalid={touched.fundId && errors.fundId}
									>
									{funds.filter((fund) => fund.fundStatusId !== 4).map((fund) =>
										<option key={fund.fundId} value={fund.fundId}>{fund.companyName} — {fund.strategyName}</option>
									)}
									</Form.Control>
								</Form.Group>

							</div>

						</div>

						<div className={'row'}>

							<div className={'col-auto'}>

								<Form.Group>
									<Form.Label htmlFor='fundingFactorEffective'>{text.fundingFactorEffective}</Form.Label>
									<Form.Control
										as='select'
										className='form-select'
										name='fundingFactorEffective'
										id='fundingFactorEffective'
										value={values.fundingFactorEffective||1}
										onChange={handleChange}
										isValid={touched.fundingFactorEffective && !errors.fundingFactorEffective}
										isInvalid={touched.fundingFactorEffective && errors.fundingFactorEffective}
									>
									{fundingFactors.map((fundingFactor) =>
										<option key={fundingFactor} value={fundingFactor}>{fundingFactor.toFixed(1)}</option>
									)}
									</Form.Control>
									<div className='form-text'>{text.fundingFactorMax}: {fund?.fundingFactorMax}</div>
								</Form.Group>

							</div>

							<div className={'col-auto'}>

								<Form.Group>
									<Form.Label htmlFor='investmentClass'>{text.investmentClass}</Form.Label>
									<Form.Control
										as='select'
										className='form-select'
										id='investmentClass'
										value={values.investmentClass}
										onChange={handleChange}
										isValid={touched.investmentClass && !errors.investmentClass}
										isInvalid={touched.investmentClass && errors.investmentClass}
									>
									{['A','B','C'].map((investmentClass) =>
										<option key={investmentClass} value={investmentClass}>{investmentClass}</option>
									)}
									</Form.Control>
								</Form.Group>

							</div>

							<div className={'col-auto'}>
								<Form.Group>
									<Form.Label htmlFor='amountToInvest'>{text.amountToInvest}</Form.Label>
									<div className='input-group'>
										<span className='input-group-text'>$</span>
										<Form.Control
											type='number'
											min={100000}
											step={1}
											name='amount'
											id='amount'
											value={values.amount}
											onChange={handleChange}
											isValid={touched.amount && !errors.amount}
											isInvalid={touched.amount && errors.amount}
										/>
									</div>
								</Form.Group>
							</div>

							<div className={'col-auto'} hidden={parseFloat(values.fundingFactorEffective)===1}>
								<Form.Group>
									<Form.Label htmlFor='tradingLevel'>{text.tradingLevel}</Form.Label>
									<div className='input-group'>
										<span className='input-group-text'>$</span>
										<Form.Control
											readOnly={true}
											disabled={true}
											id='tradingLevel'
											value={values.amount*(values.fundingFactorEffective||1)}
										/>
									</div>
								</Form.Group>
							</div>

						</div>


						<div className='my-3'>
							<button type='submit' className='btn btn-primary me-3' disabled={isSubmitting}>
								<span>{values.tempId ? text.saveChanges : text.purchase}</span>
							</button>
							<button type={'button'} className={'btn btn-outline-primary'} onClick={(e) => {resetForm();}}>{text.cancel}</button>
						</div>

						<p className='form-text mb-0'>{text.requiredFields}</p>

					</div>

				</div>

				<div className='form-text'>

					<h6>{`Minimum Investment`}</h6>

					<p>{`Initial $100,000 (Trading Level) in any Fund. Additional subscriptions may be made in multiples of $5,000 (actual cash wired, net of wire fees). Certain Funds and/or Classes may require different Minimum Investment amounts, as footnoted below.`}</p>

					<h6>{`Class of Interests`}</h6>

					<p>{`Select the applicable Class of Interests.`}</p>

					<p>{`Subscribers receive Class A, Class B, or Class C Interests depending on their aggregate Trading Level in the Platform, as described in the Memorandum. Class A, B, and C Interests are each subject to Sponsor Fees and Sales Commissions.`}</p>

					<h6>{text.fundingFactor}</h6>

					<p>{`Subscribers may select an Effective Funding Factor of a lesser amount than the listed Maximum Funding Factor by inserting an equal to or greater than one (fractional Funding Factor not permitted) but in no event more than the applicable Maximum Funding Factor. By selecting an Effective Funding Factor of a lesser amount, the Sponsor will allocate your subscription proceeds to the selected Fund among the Platform Cash Account and the corresponding Trading Company (where such amounts will be invested by the Maximum Funding Factor set by the Sponsor). Please complete the Effective Funding Factor for all selected Funds.`}</p>

				</div>

			</Form>
		</>)}
		</Formik>
	)

}

export { PurchaseForm };
