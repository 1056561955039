import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
} from '_components';


// helpers
import {
	states,
	useQueries,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// Filters
function Filters({
	model=null,
	params={},
	setParams=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang],...labelsForms[lang]};

	// roles
	const roles = JSON.parse( localStorage.getItem( `user-roles` ) || JSON.stringify( [] ) );
	const [Roles, setRoles] = useState( roles.filter((role) => {return !!params.filterByRoles && params.filterByRoles.indexOf(role) > -1}) );

	// useQueries
	useQueries([]);

	switch(model) {

		case 'company':

			return (<>

				<div className={'col-6 col-md-4 ml-auto'}>
					<div className='form-group mb-0'>
						<h6 className='badge bg-dark'>{text.status}</h6>
						<Form.Control
							as='select'
							className='form-select form-select-sm'
							id='status'
							value={params.filterByCompanyStatusId}
							onChange={(e) => {
								setParams({...params,filterByCompanyStatusId:e.currentTarget.value});
							}}
						>
							<option value={''}>{`—`}</option>
						{states.map((s) =>
							<option key={s.statusId} value={s.statusId}>{s.statusDescription}</option>
						)}
						</Form.Control>
					</div>
				</div>

			</>);

		case 'series':

			return (<>

				<div className={'col-6 col-md-4 ml-auto'}>
					<div className='form-group mb-0'>
						<h6 className='badge bg-dark'>{text.status}</h6>
						<Form.Control
							as='select'
							className='form-select form-select-sm'
							id='status'
							value={params.filterBySeriesStatusId}
							onChange={(e) => {
								setParams({...params,filterBySeriesStatusId:e.currentTarget.value});
							}}
						>
							<option value={''}>{`—`}</option>
						{states.map((s) =>
							<option key={s.statusId} value={s.statusId}>{s.statusDescription}</option>
						)}
						</Form.Control>
					</div>
				</div>

			</>);

		case 'users':

			return (<>

				<div className={'col-6 col-md-4 ml-auto'}>
					<div className='form-group mb-0'>
						<h6 className='badge bg-dark'>{text.status}</h6>
						<Form.Control
							as='select'
							className='form-select form-select-sm'
							id='status'
							onChange={(e) => {
								setParams({...params,filterByStatus:e.currentTarget.value});
							}}
						>
							<option value={''}>{`—`}</option>
						{['in_progress','pending','declined','changes_needed','approved'].map((state) =>
							<option key={state} value={text[state]}>{text[state]}</option>
						)}
						</Form.Control>
					</div>
				</div>

			{!!roles.length &&
				<div className={'col-6 col-md-4 ml-auto'}>
					<div className='form-group mb-0'>
						<h6 className='badge bg-dark'>{text.Roles}</h6>
					{roles.map(role =>
						<Form.Check
							key={role}
							type='checkbox'
							name='roles'
							id={`role-${role}`}
							value={role}
							checked={Roles.indexOf(role) > -1}
							onChange={(e) => {
								const checked = Roles.indexOf(role) > -1;
								const updated = checked ? Roles.filter((r) => {return r !== role;}) : [ ...Roles,role ];
								setRoles( updated );
								setParams({...params,filterByRoles:updated.length?updated:''});
							}}
							label={<>
								{role}
							</>}
						/>
					)}
					</div>
				</div>
			}

			</>);

		default:

			return <></>;

	}

}

export { Filters };
