const session = JSON.parse( localStorage.getItem('session') || JSON.stringify({access_token:''}) );

function get(url='',queryParams={},headers={}) {

	// remove blank parameters
	queryParams = Object.fromEntries(Object.entries(queryParams).filter(([_, v]) => v !== ''));

	const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

	return fetch(`${url}?${queryString}`, {
		method: 'GET',
		headers: session.access_token ? {
			...headers,
			'Authorization': `Bearer ${session.access_token}`,
		} : {
			...headers,
		}
	}).then(handleResponse);

}

function post(url, body, headers={}) {

	return fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${session.access_token}`,
			...headers,
		},
	}).then(handleResponse);

}

function send(url, params={}, headers={}) {

	const body = new FormData();

	for (var prop in params) {
		body.append( prop, params[prop] );
	}

	return fetch( `${url}`, {
		method: 'POST',
		body: body,
		headers: {
			'Authorization': `Bearer ${session.access_token}`,
			...headers,
		},
	} ).then(handleResponse);

}

function patch(url, body) {

	return fetch(url, {
		method: 'PATCH',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${session.access_token}`,
		},
	}).then(handleResponse);	

}

function put(url, body) {

	return fetch(url, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${session.access_token}`,
		},
	}).then(handleResponse);	

}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, body={}) {

	return fetch(url, {
		method: 'DELETE',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${session.access_token}`,
		}
	}).then(handleResponse);

}

// helper functions
function handleResponse(response) {

	return response.text().then(text => {

		let data;

		try {
			data = text && JSON.parse(text);
		}
		catch {
			data = text;
		}

		if (!response.ok) {
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;

	});

}

export const fetchWrapper = {
	get,
	post,
	send,
	patch,
	put,
	delete: _delete
};
