import {
	createContext,
} from 'react';

export const UserModel = createContext({
	isLoggedIn: false,
	login: ()=>{},
	logout: ()=>{},
	refresh: ()=>{},
});
