import React, {
	useContext,
} from 'react';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// Header
function Header() {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	// attributes
	const className = 'menu-item menu-item-type-custom menu-item-object-custom nav-item';
	const itemType = 'https://www.schema.org/SiteNavigationElement';

    return (<>
        <header className='bg-white border-bottom' id='site-header' data-headroom data-offset={0}>

			<nav className="navbar navbar-light navbar-expand-lg d-none d-lg-block" id="desktop-nav">

			<div className="container">

				<a className="navbar-brand" href="http://test.galaxyplus.io/" title="GalaxyPlus">
					<img src="http://test.galaxyplus.io/wp-content/themes/galaxyplus/img/galaxy-logo.png" alt="GalaxyPlus" width="173" height="35"/>
				</a>

				<div className="collapse navbar-collapse">

					<div className="me-auto">
						<ul id="primary-menu" className="navbar-nav align-items-lg-center d-none">
							<li itemScope="itemscope" itemType={itemType} className={className}>
								<a title="Sample Page" href="http://test.galaxyplus.io/sample-page" className="nav-link">Sample Page</a>
							</li>
						</ul>
					</div>

					<div className="d-flex" id="session-links">
					{!!User.isLoggedIn &&
						<ul className="navbar-nav">

							<li className="nav-item dropdown">

								<a className="nav-link dropdown-toggle"
									href={`/profile`} id="GalaxyPlusMenuToggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='fas fa-cog'></i>
								</a>

								<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="GalaxyPlusMenuToggle">
									<li className="nav-item">
										<a className="dropdown-item" href={`/profile`}>
											{text.profile}
										</a>
									</li>
									<li className="nav-item" hidden={!User.Client&&!User.Admin}>
										<a className="dropdown-item" href={`/purchases`}>
											{text.purchases}
										</a>
									</li>
									<li className="nav-item" hidden={!User.Client&&!User.Admin}>
										<a className="dropdown-item" href={`/purchase`}>
											{text.purchaseFunds}
										</a>
									</li>
									<li className="nav-item" hidden={!User.Admin}>
										<a className="dropdown-item" href={`/admin`}>
											{text.admin}
										</a>
									</li>
									<li className="nav-item">
										<button className="dropdown-item" onClick={User.logout}>
											{text.logOut}
										</button>
									</li>
								</ul>

							</li>

						</ul>
					}
					{!User.isLoggedIn && <>
						<a className="btn btn-outline-dark me-2"
							href={`/login`}>
							<span>{text.logIn}</span>
						</a>

						<a className="btn btn-primary"
							href={`/register`}>
							<span>{text.register}</span>
						</a>
					</>}
					</div>

				</div>

			</div>

		</nav>

			<nav className="navbar navbar-light d-lg-none" id="mobile-nav">

				<a className="nav-link me-auto ps-3" href="http://test.galaxyplus.iot/" title="GalaxyPlus">
					<img src="http://test.galaxyplus.io/wp-content/themes/galaxyplus/img/galaxy-logo.png" alt="GalaxyPlus" width="109" height="22" style={{width:'109px',height:'22px'}}/>
				</a>

				<button className="navbar-toggler border-0 py-0" type="button" data-bs-toggle="modal" data-bs-target="#mobileNav" aria-controls="mobileNav" aria-expanded="false" aria-label="Toggle navigation" disabled>
					<span className="navbar-toggler-icon opacity-0"></span>
				</button>

			</nav>

		</header>
	</>);

}

export { Header };
