/*
 * purchaseItems service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/purchaseItems
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/purchaseItemsFull`;


/**
 * GET list of purchaseItems
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


// at your service :)
export const purchaseItems = {
	baseUrl,
	get,
};
