import React, {
	useContext,
} from 'react';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// Actions
function Actions({
	model=null,
	selectAll=()=>{},
	selectedRows=[],
	setToast=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	if( !text ) console.log('text not found');

	switch(model) {

		default:

			return <></>;

	}

}

export { Actions };
