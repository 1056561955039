import React, {
	useContext,
	useEffect,
	useState,
} from 'react';


// components
import {
	Cart,
	FundList,
	PurchaseList,
	PurchaseForm,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	seriesTrusts,
	useQueries,
} from '_helpers';


// services
import {
	purchaseItems,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';

function Purchases() {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// access + redirects
	useEffect(() => {
		if( !User.isLoggedIn ) {
			window.location = `/login?redirect=/purchases`;
		}
		else if( !User.Client && !User.Admin ) {
			window.location = `/`;
		}
	}, [
		User,
	]);

	// set page title
	useEffect(() => {
		document.title = [text.purchaseFunds, text.siteTitle].join(text.titleSeparator);
	});

	return User.isLoggedIn && ( User.Client || User.Admin ) && (<>

		<h1 className='m-0 py-5'>{text.purchases}</h1>

		<PurchaseList
			/>

	</>);
}

function Purchase({
	tab='profile',
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// funds
	const [funds, setFunds] = useState([]);
	const [trustName, setTrustName] = useState('');
	const [seriesTrustId, setSeriesTrustId] = useState( User.seriesTrusts.length > 1 ? localStorage.getItem( 'seriesTrustId' ) : User.seriesTrusts[0] );
	const [fundId, setFundId] = useState(null);
	const [initialValues, setInitialValues] = useState(null);

	// load purchaseRequest from localStorage if possible
	const orderItems = JSON.parse( localStorage.getItem('purchaseItems') || JSON.stringify([]) );


	// access + redirects
	useEffect(() => {
		if( !User.isLoggedIn ) {
			window.location = `/login?redirect=/purchase`;
		}
		else if( !User.Client && !User.Admin ) {
			window.location = `/`;
		}
	}, [
		User,
	]);


	// run our queries
	const [
		{
			isLoading,
		},
	] = useQueries([
		{
			queryKey: ['purchaseItems', {filterBySeriesTrustId:seriesTrustId}],
			queryFn: () => purchaseItems.get({
				filterBySeriesTrustId: seriesTrustId,
			}),
			onSuccess: (response) => {
				setFunds(response?.items||[]);
				setTrustName(seriesTrusts.find((t) => t.seriesTrustId===seriesTrustId).seriesTrustName);
			},
			enabled: !! User.isLoggedIn && ( User.Client || User.Admin ) && !! seriesTrustId
		},
	]);


	// set page title
	useEffect(() => {
		document.title = [text.purchaseFunds, text.siteTitle].join(text.titleSeparator);
	});


	return User.isLoggedIn && ( User.Client || User.Admin ) && (<>

		<h1 className='m-0 py-5'>{text.purchaseFunds}</h1>

		{!fundId && <>

			{!!orderItems.length && <>
			<Cart
				funds={funds}
				setFunds={setFunds}
				orderItems={orderItems}
				setFundId={setFundId}
				setInitialValues={setInitialValues}
				/>
			</>}

			<div className={'card border-0'}>

				<div className={'card-body p-5'}>

				{!seriesTrustId &&
					<select className='form-select' onChange={(e)=>{
						const seriesTrustId = e.currentTarget.value;
						setSeriesTrustId( seriesTrustId );
						localStorage.setItem( 'seriesTrustId', seriesTrustId );
					}}>
						<option value={null}>{text.selectSeriesTrust}</option>
					{seriesTrusts.filter((t)=>User.seriesTrusts.indexOf(t.seriesTrustId)>-1).map((t) =>
						<option key={t.seriesTrustId} value={t.seriesTrustId}>{t.seriesTrustName}</option>
					)}
					</select>
				}

				{!!seriesTrustId && !isLoading &&
					<div className='mb-4'>
						<h2 className='d-flex'>
							<span className='pe-3'>{`Funds in ${trustName}`}</span>
							<button type={'button'}
								className={'btn btn-outline-dark btn-sm'}
								hidden={User.seriesTrusts.length===1||orderItems.length}
								onClick={() => {
									setSeriesTrustId(null);
									setFunds([]);
									localStorage.setItem( 'purchaseItems', JSON.stringify([]) );
									localStorage.removeItem( 'seriesTrustId' );
								}}><i className='fa-solid fa-arrow-left-long'></i> {text.selectDifferentTrust}</button>
						</h2>
					</div>
				}

				{!!funds.length && !isLoading &&
					<FundList
						funds={funds}
						setFundId={setFundId}
						/>
				}
				</div>

			</div>

		</>}

		{fundId && <>
			<PurchaseForm
				funds={funds}
				setFundId={setFundId}
				setInitialValues={setInitialValues}
				initialValues={initialValues || {
					fundId: fundId,
					amount: 100000,
					investmentClass: 'A',
					fundingFactorEffective: 1,
				}}/>
		</>}

	</>);

}

export * from './cart';
export * from './list';

export { Purchases };
export { Purchase };
