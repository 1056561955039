import React, {
	useContext,
	useRef,
	useState,
	useEffect,
} from 'react';


// components
import {
	ConfirmModal,
	DatePicker,
	Form,
	Notifications,
} from '_components';


// helpers
import {
	environment,
	BASE_URL,
	DROPBOX_SIGN_CLIENT_ID,
	purchaseItemInfo,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// services
import {
	assetsManager,
	purchaseRequest,
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// List
function Cart({
	funds=[],
	setFunds=()=>{},
	orderItems=[],
	setFundId=()=>{},
	setInitialValues=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// cartItems
	const [cartItems, setCartItems] = useState(orderItems);
	const [disclosure, setDisclosure] = useState( localStorage.getItem( 'disclosureHTML' ) || text.memorandumDocuments );

	// options
	const [dateSubscription, setDateSubscription] = useState(new Date());
	const [firstBusinessDay, setFirstBusinessDay] = useState(true);
	const [mondayDealingDay, setMondayDealingDay] = useState(true);
	const [memorandumDocuments, setMemorandumDocuments] = useState(true);

	// removeItem
	const removeItem = (tempId) => {
		let updatedItems = cartItems.filter(item => item.tempId !== tempId);
		// update purchaseRequest from localStorage
		localStorage.setItem( 'purchaseItems', JSON.stringify(updatedItems) );
		// reset disclosures
		localStorage.removeItem( 'disclosureHTML' );
		localStorage.removeItem( 'disclosureList' );
		setDisclosure( text.memorandumDocuments );
		// update cart
		setCartItems(updatedItems);
		// rebuild funds list
		setFunds([...funds]);
	};

	const purchaseRequestDetailsHTML = useRef(null);
	const purchaseRequestHTML = useRef(null);
	const submitRequestButton = useRef(null);

	// toasts
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// confirm modal
	const [showConfirm, setShowConfirm] = useState(false);
	const confirmCancelRequest = () => {
		setShowConfirm(true);
	};
	const cancelCancelRequest = () => {
		setShowConfirm(false);
	};

	// signature callback
	const signRequestCallback = (props) => {

		// enqueue PDF
		wordpressService.enqueuePurchaseRequestPDF(props).then((response) => {

			// clear purchaseRequest from localStorage
			localStorage.setItem( 'purchaseItems', JSON.stringify([]) );

			// redirect
			window.location = `/purchases?saved=true`;

		});

	};

	// submitRequest
	const submitRequest = () => {

		if( ! memorandumDocuments ) return;

		submitRequestButton.current.disabled = true;

		// strip tempId from orderItems
		const purchaseItems = cartItems.map(({tempId, ...props}) => props);

		purchaseRequest.make().then((response) => {

			if( response.purchaseRequestId ) {

				const purchaseRequestId = response.purchaseRequestId;

				// submit purchase
				purchaseRequest.post({
					purchaseRequestId: purchaseRequestId,
					purchaseRequestStatusId: User.Admin ? 1 : 0,
					purchaseItems: purchaseItems,
					dateSubscription: dateSubscription,
					firstBusinessDay: firstBusinessDay,
					mondayDealingDay: mondayDealingDay,
				}).then((response) => {

					// store clientName
					const clientName = `${User?.Profile?.firstName} ${User?.Profile?.lastName}`;

					// create purchaseRequestDetailsHTML
					purchaseRequestDetailsHTML.current.innerHTML = [
						`<b>${text.order}:</b> ${purchaseRequestId}`,
						`<b>${text.fullName}:</b> ${clientName}`,
						`<b>${text.phone}:</b> ${User?.Profile?.phone}`,
						`<b>${text.Email}:</b> ${User?.email}`,
					].join( '<br/>' );

					// update progress
					submitRequestButton.current.innerHTML = text.generatingPDF;

					// generate PDF
					wordpressService.makePurchaseRequestPDF({
						html: purchaseRequestHTML?.current.innerHTML,
						email: User.email,
						purchaseItems: JSON.stringify(purchaseItems),
						purchaseRequestId: purchaseRequestId,
						clientName: clientName,
					}).then((response) => {

						if( response.success && response.embedded_sign_url ) {

							const client = new window.HelloSign({
								clientId: DROPBOX_SIGN_CLIENT_ID,
							});

							const signUrl = response.embedded_sign_url;

							client.open(signUrl, {
								skipDomainVerification: 'live' !== environment,
							});

							client.on('sign', (data) => {

								signRequestCallback( {
									email: User.email,
									purchaseItems: JSON.stringify(purchaseItems),
									purchaseRequestId: purchaseRequestId,
									embedded_sign_url: response.embedded_sign_url,
									signature_request_id: response.signature_request_id,
									signature_confirmation: data,
								} );

							});

						}
						else {
							setToast([
								response.error ? `${response.error}: ` : '',
								response.error_description ? `${response.error_description}` : '',
								response.error_msg ? `${response.error_msg}` : '',
							].join(''));
							setShowToast(true);
							submitRequestButton.current.innerHTML = text.submitRequest;
							submitRequestButton.current.disabled = false;
						}

					});

				});
			}

		});

	};

	// cancelRequest
	const cancelRequest = () => {
		// clear purchaseRequest from localStorage
		localStorage.setItem( 'purchaseItems', JSON.stringify([]) );
		// reset disclosures
		localStorage.removeItem( 'disclosureHTML' );
		localStorage.removeItem( 'disclosureList' );
		setDisclosure( text.memorandumDocuments );
		// update cart
		setCartItems([]);
		// rebuild funds list
		setFunds([...funds]);
	};

	// build our disclosures HTML
	useEffect(() => {

		let disclosures = JSON.parse( localStorage.getItem('disclosureList') || JSON.stringify([]) );

		if( ! orderItems.length || !! disclosures.length ) return;

		let promises = [];

		// check for series disclosures
		orderItems.forEach((item,index) => {

			[ item.seriesTrustId, item.seriesId, item.fundId ].forEach((entityId,i) => {
				if( disclosures.indexOf( entityId ) === -1 ) {
					disclosures.push(entityId);
				}
			});

		});

		disclosures.forEach((entityId,i) => {

			promises.push( assetsManager.get({
				filterByEntityId: entityId,
				filterByFilesTypeId: '70b0fbbc-edc7-41c6-830d-2d1d44b75554',
			}) );

		});

		Promise.all(promises).then((results) => {

			let disclosureHTML = [`<p class="mb-0">${disclosure}</p>`,`<div class="list-group my-3">`];

			results.forEach((result,i) => {
				if( result.items ) {
					result.items.forEach((item,i) => {
						disclosureHTML.push(`<a class="list-group-item list-group-item-action small" target="_blank" href="${BASE_URL}${item.uri}">${item.title}</a>`);
					});
				}
			});

			disclosureHTML.push(`</div><style>.list-group:empty{display:none;}</style>`);

			setDisclosure( disclosureHTML.join('') );

			localStorage.setItem( 'disclosureList', JSON.stringify(disclosures) );
			localStorage.setItem( 'disclosureHTML', disclosureHTML.join('') );

		});

	}, [
		disclosure,
		orderItems,
	]);

	return (!!cartItems.length && <>
		<ConfirmModal
			showConfirm={showConfirm}
			handleCloseConfirm={cancelCancelRequest}
			confirmCallback={cancelRequest}
			message={text.confirmCancel}
			/>

		<Notifications
			toast={toast}
			showToast={showToast}
			setShowToast={setShowToast}
			/>

		<div className={'card border-0 mb-4'} ref={purchaseRequestHTML}>

			<div className={'card-body p-5'}>

				<h2 className='h4 mb-1 d-print-none'>{text.yourOrder}</h2>
				<p className='d-none d-print-block' ref={purchaseRequestDetailsHTML}></p>

				<div className='my-4'>
			{cartItems.map((item) =>
				<div key={`${item.fundId}-${item.tempId}`}>

					<div className={'row my-2 align-items-center'}>
						<div className={'col-9'}>
							{purchaseItemInfo(item)}
						</div>
						<div className={'col-3'}>
							<div className='d-flex justify-content-center d-print-none'>
								<button type={'button'} className={'btn btn-primary mx-2'} onClick={(e) => {setInitialValues(item);setFundId(item.fundId);}}>{text.modify}</button>
								<button type={'button'} className={'btn btn-outline-primary mx-2'} onClick={(e) => {removeItem(item.tempId);}}>{text.remove}</button>
							</div>
						</div>
					</div>

					<hr/>

				</div>
			)}
				</div>

				<div className='d-flex mt-4'>
					<Form.Group className='me-2'>
						<Form.Label htmlFor='dateSubscription'>{text.dateSubscription}</Form.Label>
						<div className='d-flex align-items-center'>
							<DatePicker
								id='dateSubscription'
								autoComplete={'off'}
								selected={dateSubscription}
								dateFormat='M/d/yyyy'
								onChange={(date) => {
									setDateSubscription( (new Date(date)) );
								}}
								className='form-control form-control-sm'
							/>
							<Form.Check
								type='checkbox'
								name='firstBusinessDay'
								id={`firstBusinessDay`}
								checked={firstBusinessDay}
								onChange={(e) => {
									setFirstBusinessDay(e.currentTarget.checked);
								}}
								label={text.firstBusinessDay}
								className='ms-2'
							/>
							<Form.Check
								type='checkbox'
								name='mondayDealingDay'
								id={`mondayDealingDay`}
								checked={mondayDealingDay}
								onChange={(e) => {
									setMondayDealingDay(e.currentTarget.checked);
								}}
								label={text.mondayDealingDay}
								className='ms-2'
							/>
						</div>
					</Form.Group>
				</div>

				<Form.Group className='mt-3 mb-2'>
					<Form.Check
						type='checkbox'
						name='memorandumDocuments'
						id={`memorandumDocuments`}
						required={true}
						checked={memorandumDocuments}
						onChange={(e) => {
							setMemorandumDocuments(e.currentTarget.checked);
						}}
						label={(<><div dangerouslySetInnerHTML={{__html: disclosure}}></div></>)}
					/>
				</Form.Group>

				<div className='alert alert-danger small py-2 px-3' hidden={!!memorandumDocuments}>{text.mustAcknowledge}</div>

				<div className='mt-3 d-print-none'>
					<button type={'button'} className={'btn btn-primary me-3'} onClick={submitRequest} ref={submitRequestButton}>{text.submitRequest}</button>
					<button type={'button'} className={'btn btn-outline-primary'} onClick={confirmCancelRequest}>{text.cancelRequest}</button>
				</div>

			</div>

		</div>
	</>);

}

export { Cart };
