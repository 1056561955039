import React, {
	useContext,
} from 'react';


// components
import {
	Screener,
} from '_components';


// helpers
import {
	ADMIN_FILE_COLUMNS,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// services
import {
	assetsManager,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// List
function List({
	enableFilters=true,
	enableQueryParams=false,
	defaultParams={},
	staticParams={},
	columns=null,
	setFileId=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	if( !text ) console.log('text not found');

	// column preferences
	columns = columns || ADMIN_FILE_COLUMNS;

	const columnPrefs = JSON.parse( localStorage.getItem( `columns-files` ) || JSON.stringify( [] ) );

	if( columnPrefs.length ) {
		columns = columns.map(column => {
			let pref = columnPrefs.find(f=>f.field===column.field);
			return pref ? { ...column, visible: pref.visible } : { ...column };
		});
	}

	return (<>
		<Screener
			columns={columns}
			primary={'fileId'}
			service={assetsManager}
			defaultParams={{
				sortBy: 'title',
				orderBy: 'ASC',
				...defaultParams,
			}}
			staticParams={{
				...staticParams,
			}}
			enableQueryParams={false}
			queryKey={'files'}
			queryEnabled={!!User.Admin}
			searchEnabled={false}
			fns={{}}
			toolbar={<></>}
			/>
	</>);

}

export { List };
