import {
	WP_AJAX_URL,
	fetchWrapper,
} from '_helpers';

const baseUrl = `${WP_AJAX_URL}`;

function authenticate(username, password) {
	return fetchWrapper.send(`${baseUrl}`, {
		action: 'galaxy_auth',
		username: username,
		password: password,
	});
}

function refreshToken(refresh_token) {
	return fetchWrapper.send(`${baseUrl}`, {
		action: 'galaxy_refresh',
		refresh_token: refresh_token,
	});
}

function apiRequest(params) {
	return fetchWrapper.send(`${baseUrl}`, {
		action: 'galaxy_api',
		...params,
	});
}

function request(params) {
	return fetchWrapper.send(`${baseUrl}`, {
		action: 'galaxy_wordpress',
		...params,
	});
}

/* AJAX request methods */
function deleteUser(email) {
	return request({
		email: email,
		method: 'galaxy_delete_user',
	});
}

function getUserDocs(params) {
	return request({
		...params,
		method: 'galaxy_get_user_docs',
	});
}

function makePurchaseRequestPDF(params) {
	return request({
		...params,
		method: 'galaxy_make_purchase_request_pdf',
	});
}

function enqueuePurchaseRequestPDF(params) {
	return request({
		...params,
		method: 'galaxy_enqueue_purchase_request_pdf',
	});
}

function sendEmail(params) {
	return request({
		...params,
		method: 'galaxy_send_email',
	});
}

function getUserMeta(params) {
	return request({
		...params,
		method: 'galaxy_get_user_meta',
	});
}

function setUserStatus(params) {
	return request({
		...params,
		method: 'galaxy_set_user_status',
	});
}

function uploadUsers(params) {
	return request({
		...params,
		method: 'galaxy_upload_users',
	});
}

export const wordpressService = {
	baseUrl,
	authenticate,
	refreshToken,
	apiRequest,
	request,
	deleteUser,
	getUserDocs,
	makePurchaseRequestPDF,
	enqueuePurchaseRequestPDF,
	sendEmail,
	getUserMeta,
	setUserStatus,
	uploadUsers,
};
