import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	InputGroup,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	seriesTrusts,
	yup,
} from '_helpers';


// services
import {
	userService,
	wordpressService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';
import { labels as labelsLogin } from '_i18n/Login';


// LoginForm
function LoginForm({
	redirect='',
	setShowLogin=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsLogin[lang]};

	// variables
	const [showPassword, setShowPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(
		localStorage.getItem('rememberMe') === 'true'
	);

	// error handling
	const [error, setError] = useState(false);

	// form schema
	const schema = yup.object().shape({
		username: yup.string().required(),
		password: yup.string().required(),
	});

	// login submit handler
	const onSubmit = (values, formikBag) => {

		setError(false);

		// set rememberMe in localStorage
		localStorage.setItem( 'rememberMe', rememberMe );

		// map for default oAuth error messages
		const messages = {
			'The token request was rejected by the authentication server.': text.noAccount,
			'The specified username/password couple is invalid.': text.invalidPw,
		};

		wordpressService.authenticate( values.username, values.password )

			.then((response) => {

				if( response && !response.error ) {

					const max_age = (response.expires_in * 1000);

					const userData = {
						expires: rememberMe ? false : true,
						expires_in: ( new Date() ).getTime() + max_age,
						access_token: response.access_token,
						refresh_token: response.refresh_token,
						email: values.username,
						seriesTrusts: [],
					};

					// get user account and update session
					userService.getByEmail( userData.email, {'Authorization': `Bearer ${userData.access_token}`} ).then((response) => {

						if( response.RoleNames.indexOf('Member Limited Access') === -1 ) {

							const Admin = response.RoleNames.indexOf('Administrator') > -1;

							// set seriesTrusts
							seriesTrusts.forEach((s,i) => {

								( Admin || response.RoleNames.indexOf(s.seriesTrustName) > -1 ) && userData.seriesTrusts.push(s.seriesTrustId);

							});

							User.login({
								...userData,
								Profile: response.UserProfile,
								UserId: response.UserId,
								Roles: response.RoleNames.join(','),
								Admin: Admin,
								Client: response.RoleNames.indexOf('Client') > -1,
							});

							document.cookie = `galaxy_access=true;path=/;max-age=${max_age}`;

						}
						else {

							redirect = `/login?action=suspended`;

						}

						setShowLogin(false);

						if( redirect ) {
							window.location = redirect;
						}

					}).catch((error) => {

						setError( error.toString() );

					});

				}
				else {

					let error_description = response.error_description in messages ? messages[response.error_description] : ( response.error_description ? response.error_description : `Something went wrong…` );

					setError(error_description);

				}

				// reset 'isSubmitting'
				formikBag.setSubmitting(false);

			}

		).catch((error) => {

			setError( error.toString() );

		});

	};

    return User.isLoggedIn ? ( <></> ) : (
        <Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={{
				username: '',
				password: '',
				redirect: redirect,
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<Form.Group className='mb-3'>
					<Form.Label htmlFor='username'>{text.username}</Form.Label>
					<Form.Control
						type='email'
						name='username'
						id='username'
						value={values.username}
						onChange={handleChange}
						isValid={touched.username && !errors.username}
						isInvalid={touched.username && errors.username}
					/>
					<Form.Control.Feedback type='invalid'>
						{errors.username}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className='mb-3'>

					<Form.Label htmlFor='password'>{text.Password}</Form.Label>

					<InputGroup>

						<Form.Control
							type={showPassword?'text':'password'}
							name='password'
							id='password'
							value={values.password}
							onChange={handleChange}
							isValid={touched.password && !errors.password}
							isInvalid={touched.password && errors.password}
						/>

						<button type='button' className='btn bg-white' onClick={()=>setShowPassword(!showPassword)}>
							<i className={'fa-solid fa-fw fa-eye'+(!showPassword?'':'-slash')}></i>
						</button>

						<Form.Control.Feedback type='invalid'>
							{errors.password}
						</Form.Control.Feedback>

					</InputGroup>

				</Form.Group>

				<Form.Group className='d-flex mb-3'>
					<div className="form-check">
						<input type="checkbox"
							className="form-check-input"
							id="rememberMe"
							value="1"
							checked={rememberMe}
							onChange={(e) => setRememberMe(e.currentTarget.checked)}
							/>
						<label className="form-check-label" htmlFor="rememberMe">{text.rememberMe}</label>
					</div>
					<small className="form-text text-muted ms-auto"><a href={`/login?action=forgot-password`}>{text.forgotPassword}</a></small>
				</Form.Group>

				<Form.Group>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.login}</span>
					</button>
				</Form.Group>

			</Form>
		</>)}
		</Formik>
    );

}

export { LoginForm };
