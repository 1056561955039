/*
 * seriesTrust service
 *
 * @link https://galaxyapi.enki.us/swagger/index.html#/seriesTrusts
 *
 */


// helpers
import {
	API_URL,
	fetchWrapper,
} from '_helpers';


// baseUrl
const baseUrl = `${API_URL}/seriesTrusts`;


/**
 * GET list of seriesTrusts
 *
 * @param  object queryParams
 * @return object response
 */
function get(queryParams={}) {
	return fetchWrapper.get(baseUrl, queryParams);
}


/**
 * GET by seriesTrustId
 *
 * @param  string seriesTrustId
 * @return object response
 */
function getById(seriesTrustId) {
	return fetchWrapper.get(`${baseUrl}/${seriesTrustId}`);
}


/**
 * POST
 *
 * @param  object params
 * @return object response
 */
function post(params={}) {
	return fetchWrapper.post(baseUrl, params);
}


/**
 * Update series record via PUT
 *
 * @param  object params
 * @return object response
 */
function update(params={}) {
	return fetchWrapper.put(baseUrl, params);
}


/**
 * DELETE seriesTrust - accepts string or object with seriesTrustId prop
 *
 * @param  object|string props
 * @return object response
 */
function _delete(props) {
	const seriesTrustId = 'object' === typeof props ? props.seriesTrustId : props;
	return fetchWrapper.delete(`${baseUrl}/${seriesTrustId}`);
}


// at your service :)
export const seriesTrustService = {
	baseUrl,
	get,
	getById,
	post,
	update,
	delete: _delete,
};
