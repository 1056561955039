import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Notifications,
} from '_components';


// helpers
import {
	BASE_URL,
} from '_helpers';


// models
import {
	UserModel,
} from '_models';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';


// List
function List({
	funds=[],
	setFundId=()=>{},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang]};

	// load purchaseRequest from localStorage if possible
	const purchaseItems = JSON.parse( localStorage.getItem('purchaseItems') || JSON.stringify([]) );

	// error handling & toasts
	const [error] = useState(false);
	const [toast] = useState('');
	const [showToast, setShowToast] = useState(false);

	return (<>

		<Notifications
			error={error}
			toast={toast}
			showToast={showToast}
			setShowToast={setShowToast}
			/>

		<div>
		{funds.map((fund) =>
			<div className={'row mt-2 mb-4'} key={fund.fundId}>
				<div className={'col-9'}>
					<h6 className={'m-0'}>{fund.companyName} <span className={'fw-normal'}>— {fund.strategyName}</span></h6>
					<p className='m-0'>
						{fund.seriesTrustName}
						<span className='px-1'>—</span>
					{!!fund.tearsheet &&
						<a target='_blank' rel='noreferrer' href={`${BASE_URL}${fund.tearsheet}`}>{fund.seriesName} ({fund.seriesFId})</a>
					}
					{!fund.tearsheet &&
						<span>{fund.seriesName} ({fund.seriesFId})</span>
					}
					</p>
				</div>
				<div className={'col-3'}>
					<button type={'button'} className={'btn btn-primary w-100'}
						hidden={fund.fundStatusId===4}
						disabled={purchaseItems.find((item) => {
							return item.fundId===fund.fundId;
						})}
						onClick={(e) => {
							setFundId(fund.fundId);
						}}>
						{text.purchase}
					</button>
				</div>
			</div>
		)}
		</div>

	</>);

}

export { List };
