import React, {
	useContext,
	useState,
} from 'react';


// components
import {
	Form,
	Formik,
	Notifications,
} from '_components';


// models
import {
	UserModel,
} from '_models';


// helpers
import {
	yup,
} from '_helpers';


// services
import {
	companyService,
} from '_services';


// i18n
import { labels as labelsGlobal } from '_i18n/Global';
import { labels as labelsForms } from '_i18n/Forms';


// CompanyForm
function CompanyForm({
	initialValues={},
}) {

	// User
	const User = useContext(UserModel);

	// i18n
	const lang = User.lang || 'en';
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling & toasts
	const [error, setError] = useState(false);
	const [toast, setToast] = useState('');
	const [showToast, setShowToast] = useState(false);

	// form schema
	let fields = {
		companyName: yup.string().required(),
		location: yup.string().required(),
	};

	const schema = yup.object().shape(fields);

	// submit handler
	const onSubmit = (values, formikBag) => {

		// clear errors & toasts
		setError(false);

		// use object destructuring and the spread operator to omit props from values
		let {...Company} = values;

		// update Company
		if( Company.companyId ) {

			companyService.update(Company).then((response) => {
				// show toast
				setToast(text.companySaved);
				setShowToast(true);
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
			});

		}
		// add Company
		else {

			companyService.post(Company).then((response) => {
				// show toast
				setToast(text.companySaved);
				setShowToast(true);
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
			});

		}

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (<>

			<Notifications
				error={error}
				toast={toast}
				showToast={showToast}
				setShowToast={setShowToast}
				/>

			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='companyName'>{text.companyName}*</Form.Label>
							<Form.Control
								type='text'
								name='companyName'
								id='companyName'
								value={values.companyName}
								onChange={handleChange}
								isValid={touched.companyName && !errors.companyName}
								isInvalid={touched.companyName && errors.companyName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='row mb-4'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='location'>{text.location}*</Form.Label>
							<Form.Control
								type='text'
								name='location'
								id='location'
								value={values.location}
								onChange={handleChange}
								isValid={touched.location && !errors.location}
								isInvalid={touched.location && errors.location}
							/>
						</Form.Group>

					</div>

				</div>

				<Form.Group>
					<button type='submit' className='btn btn-primary d-block w-100' disabled={isSubmitting}>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='form-text'>{text.requiredFields}</p>

			</Form>
		</>)}
		</Formik>
	)

}

export {
	CompanyForm,
};
