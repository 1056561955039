import 'index.css';

import React from 'react';

import { App } from '_app';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

const script = document.createElement('script');
script.src = 'https://cdn.hellosign.com/public/js/embedded/v2.10.0/embedded.production.min.js';
script.crossorigin = 'anonymous';
document.body.appendChild(script);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
